import { Globals } from 'src/app/globals';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import moment from 'moment-timezone';
import * as _ from 'lodash';

@Component({
  selector: 'app-two',
  templateUrl: './two.component.html',
  styleUrls: ['./two.component.scss']
})
export class TwoComponent implements OnInit {

  globals: any;
  g: any;
  agesGender: any = [
    {
      id: '1317',
      name: '13-17',
      value: '10'
    },
    {
      id: '1824',
      name: '18-24',
      value: '20'
    },
    {
      id: '2534',
      name: '25-34',
      value: '30'
    },
    {
      id: '3544',
      name: '35-44',
      value: '40'
    },
    {
      id: '4554',
      name: '45-54',
      value: '50'
    },
    {
      id: '5564',
      name: '55-64',
      value: '60'
    },
    {
      id: '65',
      name: '+65',
      value: '70'
    }
  ];

  devices: any = [
    {
      id: 'mobile',
      name: 'Mobile'
    },
    {
      id: 'desktop',
      name: 'Desktop'
    },
    {
      id: 'tablet',
      name: 'Tablet'
    },
    {
      id: 'tv',
      name: 'TV'
    }
  ];
  operatives: any = [
    {
      id: 'android',
      name: 'Android'
    },
    {
      id: 'ios',
      name: 'IOS'
    },
    {
      id: 'mac',
      name: 'macOS'
    },
    {
      id: 'windows',
      name: 'Windows'
    }
  ];

  sk: any;
  sd: any;
  countries: any;
  doubleCounter = 100;

  maleCounter = 0;
  femaleCounter = 0;
  regionsKeyCounter = {};
  devicesKeyCounter = {};
  osKeyCounter = {};

  constructor(globals: Globals, private service: AdminService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.g = this.globals.campaign;
    this.getCountries();
    for (const f of this.g.formats) {
      f.realBudget = Math.round((f.budget * (parseFloat(f.feePercent) / 100)) / this.g.trm);
      if (f.creatives) {
        for (const c of f.creatives) {
          this.doubleCounter + 1;
        }
      }
    }
  }

  getCountries() {
    this.globals.loading = true;
    this.service.getCountries().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.countries = data;
        this.getRegions();
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }


  addDay(id: any) {
    let f = this.g.formats;
    const p = _.findIndex(f, {_id: id});
    let c;
    if (f[p].days) {
      c = f[p].days.length;
      f[p].days.push({
        id: `day${id}${c}`,
        counter: c,
      })
    } else {
      f[p].days = [];
      c = f[p].days.length;
      f[p].days.push({
        id: `day${id}${c}`,
        counter: c,
      })
    }
    this.validateSave();
  }

  addCreative(id: any) {
    let f = this.g.formats;
    const p = _.findIndex(f, {_id: id});
    let c;
    this.doubleCounter = this.doubleCounter + 1;
    if (f[p].creatives) {
      c = f[p].creatives.length;
      this.doubleCounter += f[p].creatives.length;
      f[p].creatives.push({
        id: `${id}${c}`,
        selector: this.doubleCounter,
        counter: c,
        file: ''
      })
    } else {
      f[p].creatives = [];
      c = f[p].creatives.length;
      f[p].creatives.push({
        id: `${id}${c}`,
        selector: this.doubleCounter,
        counter: c,
        file: ''
      })
    }
    this.validateSave();
  }

  removeCreative(idFormat: any, id: any) {
    Swal.fire({
      title: "¿Esta Seguro de querer eliminar este creativo?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        let f = this.g.formats;
        const p = _.findIndex(f, {_id: idFormat});
        const positionCreative = _.findIndex(f[p].creatives, {id: id});
        f[p].creatives.splice(positionCreative, 1);  
      } else if (result.isDenied) {
      }
    });
    this.validateSave();
  }

  addPrints(id: any) {
    let f = this.g.formats;
    const p = _.findIndex(f, {_id: id});
    let c;
    if (f[p].prints) {
      c = f[p].prints.length;
      f[p].prints.push({
        id: `print${id}${c}`,
        counter: c,
        file: ''
      })
    } else {
      f[p].prints = [];
      c = f[p].prints.length;
      f[p].prints.push({
        id: `print${id}${c}`,
        counter: c,
        file: ''
      })
    }
    this.validateSave();
  }


  removePrint(idFormat: any, id: any) {
    Swal.fire({
      title: "¿Esta Seguro de querer eliminar este Print?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        let f = this.g.formats;
        const p = _.findIndex(f, {_id: idFormat});
        const positionPrint = _.findIndex(f[p].prints, {id: id});
        f[p].prints.splice(positionPrint, 1);  
      } else if (result.isDenied) {
      }
    });
    this.validateSave();
  }

  uploadPrint(idFormat: any, id: any) {
    let f = this.g.formats;
    let file = (document.getElementById(`printInput${id}`) as HTMLInputElement).files[0];
    this.globals.loading = true;
    var fd = new FormData();
    fd.append('fileName', file);
    fd.append('route', `dataroom/prints/${id}`);
    this.service.upload(fd).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        const position = _.findIndex(f, {_id: idFormat});
        const positionPrint = _.findIndex(f[position].prints, {id: id});
        f[position].prints[positionPrint].file = data.message.root;
        let c = f[position].prints[positionPrint];
        if (c.file.match('.jpeg') || c.file.match('.png') || c.file.match('.jpg')) {
          c.type = 'image'
        }
        if (c.file.match('.mp4') || c.file.match('.mov') || c.file.match('.m4v')) {
          c.type = 'video'
        }
        if (c.file.match('.mp3') || c.file.match('.wav')) {
          c.type = 'audio'
        }
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
    this.validateSave();
  }

  
  uploadCreative(idFormat: any, id: any) {
    let f = this.g.formats;
    let file = (document.getElementById(`creativeInput${id}`) as HTMLInputElement).files[0];
    this.globals.loading = true;
    var fd = new FormData();
    fd.append('fileName', file);
    fd.append('route', `dataroom/creatives/${id}`);
    this.service.upload(fd).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        const position = _.findIndex(f, {_id: idFormat});
        const positionCreative = _.findIndex(f[position].creatives, {id: id});
        f[position].creatives[positionCreative].file = data.message.root;
        let c = f[position].creatives[positionCreative];
        if (c.file.match('.jpeg') || c.file.match('.png') || c.file.match('.jpg')) {
          c.type = 'image'
        }
        if (c.file.match('.mp4') || c.file.match('.mov') || c.file.match('.m4v')) {
          c.type = 'video'
        }
        if (c.file.match('.mp3') || c.file.match('.wav')) {
          c.type = 'audio'
        }
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
    this.validateSave();
  }

  deleteFile(idFormat: any, id: any) {
    let f = this.g.formats;
    this.globals.loading = false;
    const position = _.findIndex(f, {_id: idFormat});
    const positionCreative = _.findIndex(f[position].creatives, {id: id});
    f[position].creatives[positionCreative].file = '';
    this.validateSave();
  }

  deletePrint(idFormat: any, id: any) {
    let f = this.g.formats;
    this.globals.loading = false;
    const position = _.findIndex(f, {_id: idFormat});
    const positionPrint = _.findIndex(f[position].prints, {id: id});
    f[position].prints[positionPrint].file = '';
    this.validateSave();
  }

  addSegment(id: any, counter: any) {
    let f = this.g.formats;
    const p = _.findIndex(f, {_id: id});
    const pd = _.findIndex(f[p].days, {counter: counter});
    this.sk = f[p].keys;
    this.sd = f[p].days[pd];
    this.globals.loading = true;
    if (pd !== 0) {
      setTimeout(() => {
        for (const a of f[p].days[0].segment.genderAge) {
          let id; 
          if (a.age === '+65') {
            id = a.age.replace('+65', '65');
          } else {
            id = a.age.replace('-', '');
          }
          (document.getElementById(`male${id}`) as HTMLInputElement).value = a.male;
          (document.getElementById(`female${id}`) as HTMLInputElement).value = a.female;
          this.setValue('male', id);
          this.setValue('female', id);
        }
        for (const r of this.globals.campaign.regions) {
          for (const rr of f[p].days[0].segment.map) {
            if (r.name === rr.regionName) {
              for (const k of this.sk) {
                (document.getElementById(`region${r._id}${k.id}`) as HTMLInputElement).value = rr[k.id];
              }
            }
          }
        }
        for (const d of f[p].days[0].segment.devices) {
          for (const k of this.sk) {
            (document.getElementById(`device${d.id}${k.id}`) as HTMLInputElement).value = d[k.id];
          }
        }
        for (const o of f[p].days[0].segment.os) {
          for (const k of this.sk) {
            (document.getElementById(`os${o.id}${k.id}`) as HTMLInputElement).value = o[k.id];
          }
        }
        this.globals.loading = false;
        this.calculateAges();
        this.calulateRegionsbyKey();
        this.calculateDevices();
        this.calculateOs();
      }, 2000);
    } else {
      this.globals.loading = false;
    }
    this.validateSave();
  }

  editSegment(id: any, counter: any) {
    this.globals.loading = true;
    let f = this.g.formats;
    const p = _.findIndex(f, {_id: id});
    const pd = _.findIndex(f[p].days, {counter: counter});
    this.sk = f[p].keys;
    this.sd = f[p].days[pd];
    setTimeout(() => {
      for (const a of this.sd.segment.genderAge) {
        let id; 
        if (a.age === '+65') {
          id = a.age.replace('+65', '65');
        } else {
          id = a.age.replace('-', '');
        }
        (document.getElementById(`male${id}`) as HTMLInputElement).value = a.male;
        (document.getElementById(`female${id}`) as HTMLInputElement).value = a.female;
        this.setValue('male', id);
        this.setValue('female', id);
      }
      for (const r of this.globals.campaign.regions) {
        for (const rr of this.sd.segment.map) {
          if (r.name === rr.regionName) {
            for (const k of this.sk) {
              (document.getElementById(`region${r._id}${k.id}`) as HTMLInputElement).value = rr[k.id];
            }
          }
        }
      }
      for (const d of this.sd.segment.devices) {
        for (const k of this.sk) {
          (document.getElementById(`device${d.id}${k.id}`) as HTMLInputElement).value = d[k.id];
        }
      }
      for (const o of this.sd.segment.os) {
        for (const k of this.sk) {
          (document.getElementById(`os${o.id}${k.id}`) as HTMLInputElement).value = o[k.id];
        }
      }
      this.globals.loading = false;
      this.calculateAges();
      this.calulateRegionsbyKey();
      this.calculateDevices();
      this.calculateOs();
    }, 2000);
    this.validateSave();
  }

  setValue(gender: any, id: any) {
    let progress;
    let input = (document.getElementById(`${gender}${id}`) as HTMLInputElement).value;
    if (gender === 'male') {
      progress = (document.getElementById(`ariaValueMale${id}`) as HTMLElement);
    } else {
      progress = (document.getElementById(`ariaValueFemale${id}`) as HTMLElement);
    }
    progress.style.width = `${input}%`;
    progress.innerText = `${input}%`;
    this.validateSave();
    this.calculateAges();
  }

  calculateAges() {
    this.maleCounter = 0;
    this.femaleCounter = 0;
    for (const g of this.agesGender) {
      this.maleCounter += parseInt((document.getElementById(`male${g.id}`) as HTMLInputElement).value);
      this.femaleCounter += parseInt((document.getElementById(`female${g.id}`) as HTMLInputElement).value);
    }
  }

  calulateRegionsbyKey() {
    for (const k of this.sk) {
      this.regionsKeyCounter[k.id] = 0;
      for (const r of this.g.regions) {
        this.regionsKeyCounter[k.id] += parseInt((document.getElementById(`region${r._id}${k.id}`) as HTMLInputElement).value);
      }
    }
  }

  calculateDevices() {
    for (const k of this.sk) {
      this.devicesKeyCounter[k.id] = 0;
      for (const d of this.devices) {
        this.devicesKeyCounter[k.id] += parseInt((document.getElementById(`device${d.id}${k.id}`) as HTMLInputElement).value);
      }
    }
  }

  calculateOs() {
    for (const k of this.sk) {
      this.osKeyCounter[k.id] = 0;
      for (const o of this.operatives) {
        this.osKeyCounter[k.id] += parseInt((document.getElementById(`os${o.id}${k.id}`) as HTMLInputElement).value);
      }
    }
  }

  buildSegmentData() {
    this.sd.segment =  {};
    this.sd.segment.genderAge = [];
    for (const g of this.agesGender) {
      let gender = {
        age: g.name,
        female: parseInt((document.getElementById(`female${g.id}`) as HTMLInputElement).value),
        male: parseInt((document.getElementById(`male${g.id}`) as HTMLInputElement).value),
      }
      this.sd.segment.genderAge.push(gender);
    }
    this.sd.segment.map = [];
    for (const r of this.g.regions) {
      let region = {
        countryId: this.globals.countryDomain.amcharts,
        countryName: this.globals.countryDomain.name,
        regionId: r.amcharts,
        regionName: r.name
      }
      for (const k of this.sk) {
        region[k.id] = (document.getElementById(`region${r._id}${k.id}`) as HTMLInputElement).value;
      }
      this.sd.segment.map.push(region);
    }
    this.sd.segment.devices = [];
    for (const d of this.devices) {
      let device = {
        id: d.id,
      }
      for (const k of this.sk) {
        device[k.id] = (document.getElementById(`device${d.id}${k.id}`) as HTMLInputElement).value;
      }
      this.sd.segment.devices.push(device);
    }
    this.sd.segment.os = [];
    for (const o of this.operatives) {
      let system = {
        id: o.id,
      }
      for (const k of this.sk) {
        system[k.id] = (document.getElementById(`os${o.id}${k.id}`) as HTMLInputElement).value;
      }
      this.sd.segment.os.push(system);
    }
    this.validateSave();
  }

  getRegions() {
    const country = this.g.country;
    const cp = _.findIndex(this.countries, {_id: country});
    this.globals.countryDomain = this.countries[cp];
    this.globals.loading = true;
    this.service.getRegions(country).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.globals.campaign.regions = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
    this.validateSave();
  }

  setTag(idFormat: any, id: any) {
    let f = this.g.formats;
    const tag = (document.getElementById(`tagCreative${id}`) as HTMLInputElement).value;
    let prev = (document.getElementById(`prevCreative${id}`) as HTMLElement);
    prev.innerHTML = '';
    let frame = document.createElement('iframe');
    frame.width = '100%';
    frame.height = '600';
    frame.src = 'data:text/html;charset=utf-8,' + encodeURI(tag);
    prev.appendChild(frame);
    const position = _.findIndex(f, {_id: idFormat});
    const positionCreative = _.findIndex(f[position].creatives, {id: id});
    f[position].creatives[positionCreative].file = tag;
    let c = f[position].creatives[positionCreative];
    c.type = 'tag'
  }

  validateSave() {
    let g = this.globals.campaign;
    if (g.formats) {
      for (const f of this.g.formats) {
        if (f.days && f.creatives) {
          for (const d of f.days) {
            if (d.segment) {
              g.readySaveTwo = true;
            } else {
              g.readySaveTwo = false;
            }
          }
        } else {
          g.readySaveTwo = false;
        }
      }
    }
  }

  enableUltraPaste(fId: any, key: any) {
    let f = this.g.formats;
    const p = _.findIndex(f, {_id: fId});
    this.globals.ultraPaste = {};
    this.globals.ultraPasteEnabled = true;
    this.globals.ultraPaste.formatId = fId;
    this.globals.ultraPaste.key = key;
    this.globals.ultraPaste.pf = p;
    let input = (document.getElementById('ultraPaste') as HTMLInputElement);
    input.value = '';
    input.select();
    input.setSelectionRange(0, 99999999999999999999999999999999999999999999999999999999999999999);
  }

  ultraPaste() {
    let f = this.g.formats;
    let up = this.globals.ultraPaste;
    let values = (document.getElementById('ultraPaste') as HTMLInputElement).value;
    let qty = values.split(' ');
    for (let v = 0; v < values.length; v++) {
      if (up.key === 'date') {
        (document.getElementById(`date${f[up.pf].days[v].id}`) as HTMLInputElement).value = qty[v];
      } else {
        (document.getElementById(`dayData${up.formatId}${f[up.pf].days[v].counter}${up.key}`) as HTMLInputElement).value = qty[v];
      }
    }
    this.globals.ultraPasteEnabled = false;
  }


  enableCreativeUltraPaste(fId: any, cId: any, key: any, selector: any) {
    console.log(fId, cId, key, selector);
    let f = this.g.formats;
    const p = _.findIndex(f, {_id: fId});
    const pc = _.findIndex(f[p].creatives, {id: cId});
    this.globals.ultraCreativePaste = {};
    this.globals.ultraCreativePasteEnabled = true;
    this.globals.ultraCreativePaste.formatId = fId;
    this.globals.ultraCreativePaste.key = key;
    this.globals.ultraCreativePaste.selector = selector;
    this.globals.ultraCreativePaste.pc = pc;
    this.globals.ultraCreativePaste.pf = p;
    let input = (document.getElementById('ultraCreativePaste') as HTMLInputElement);
    input.value = '';
    input.select();
    input.setSelectionRange(0, 99999999999999999999999999999999999999999999999999999999999999999);
  }

  CreativeultraPaste() {
    let f = this.g.formats;
    let up = this.globals.ultraCreativePaste;
    let values = (document.getElementById('ultraCreativePaste') as HTMLInputElement).value;
    let qty = values.split(' ');
    for (let v = 0; v < values.length; v++) {
      if (up.key === 'date') {
        (document.getElementById(`creativeDate${f[up.pf].days[v].id}${up.selector}`) as HTMLInputElement).value = qty[v];
      } else {
        (document.getElementById(`creative${f[up.pf].days[v].id}${up.selector}${up.key}`) as HTMLInputElement).value = qty[v];
      }
    }
    this.globals.ultraPasteEnabled = false; 
  }

}
