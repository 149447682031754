<div class="panel" *ngIf="globals.user">
    <div class="row">
        <div class="col-sm-4 col-xl-2">
            <div class="menu">
                <div class="profile">
                    <div class="picture" attr.style="background-image: url({{ globals.user.picture }});"></div>
                    <div class="down">
                        <div class="name tex-truncate">{{ globals.user.name }}</div>
                        <div class="email tex-truncate">{{ globals.user.email }}</div>
                    </div>
                    <!-- <div class="downer"><i class="lni lni-chevron-down"></i></div> -->
                </div>
                <div class="items">
                    <div class="item" [class.active]="section === 'users'" (click)="selectSection('users')"><i class="lni lni-user"></i> Usuarios</div>
                    <div class="item" [class.active]="section === 'companies'" (click)="selectSection('companies')"><i class="lni lni-apple-brand"></i> Compañias</div>
                    <div class="item" [class.active]="section === 'brands'" (click)="selectSection('brands')"><i class="lni lni-lineicons-symbol"></i> Marcas</div>    
                </div>
            </div>
        </div>
        <div class="col-sm-8 col-xl-10 apps">
            <app-users *ngIf="section === 'users'"></app-users>
            <app-companies *ngIf="section === 'companies'"></app-companies>
            <app-brands *ngIf="section === 'brands'"></app-brands>
        </div>
    </div>
</div>