import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';


@Component({
  selector: 'app-netflixtemplate01',
  templateUrl: './netflixtemplate01.component.html',
  styleUrls: ['./netflixtemplate01.component.scss']
})
export class Netflixtemplate01Component implements OnInit {

  globals: any;
  colors = [];
  c: any;
  loading = true;

  constructor(globals: Globals) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.c = this.globals.campaign;
    this.colors =this.c.template.colors;
    let styles = document.createElement('style');
    styles.id = 'colorsData';
    document.querySelector('body').appendChild(styles);
    document.getElementById('colorsData').innerHTML = `
    body {
      --primary: #${this.colors[0]};
      --secondary: #${this.colors[1]};
      --relevant: #${this.colors[2]};
    }
    `;
    setTimeout(() => {
      this.loading = false;
    }, 3000);
  }

  
  openReport(name: any, format: any) {
    this.globals.report = true;
    this.globals.selectedFormat = format;
    this.globals.selectedReport = {
      name,
      id: format.id,
      st: format.startDate,
      ed: format.endDate
    }
  }

}
// backgroundImage