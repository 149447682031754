import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Daterangepicker } from 'ng2-daterangepicker';
import { FormsModule } from '@angular/forms';
import { CreateRoutingModule } from './create-routing.module';
import { CreateComponent } from './create.component';
import { OneComponent } from '../importables/one/one.component';
import { ThreeComponent } from './../importables/three/three.component';
import { TwoComponent } from './../importables/two/two.component';


@NgModule({
  declarations: [
    CreateComponent,
    OneComponent,
    TwoComponent,
    ThreeComponent
  ],
  imports: [
    CommonModule,
    CreateRoutingModule,
    Daterangepicker,
    FormsModule,
    CommonModule
  ]
})
export class CreateModule { }
