import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { AdminService } from '../services/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import moment from 'moment-timezone';

@Component({
  selector: 'app-panel',
  templateUrl: './panel.component.html',
  styleUrls: ['./panel.component.scss']
})
export class PanelComponent implements OnInit {

  globals: any;
  data: any;
  filterData: any;
  startDate: any = moment('2023-01-01').startOf('month').format('YYYY-MM-DD');
  endDate: any = moment('2024-12-31').endOf('month').format('YYYY-MM-DD');
  options: any = {
    alwaysShowCalendars: true,
    ranges: {
      'Hoy': [moment(), moment()],
      'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Ultimos 7 Días': [moment().subtract(6, 'days'), moment()],
      'Ultimos 30 Días': [moment().subtract(29, 'days'), moment()],
      'Este mes': [moment().startOf('month'), moment().endOf('month')],
      'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  };

  constructor(private service: AdminService, globals: Globals, private active: ActivatedRoute, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.getUsers();
  }

  selectedDate(value: any) {
    this.startDate = moment(value.start).format('YYYY-MM-DD');
    this.endDate = moment(value.end).format('YYYY-MM-DD');
    this.getData();
  }

  getUsers() {
    this.globals.loading = true;
    this.service.getUsers().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.globals.users = data;
        this.getCompanies();
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  
  getCompanies() {
    this.globals.loading = true;
    this.service.getCompanies().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.globals.companies = data;
        this.getBrands();
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  getBrands() {
    this.globals.loading = true;
    this.service.getBrands().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.globals.brands = data;
        this.getData();
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  getData() {
    this.globals.loading = true;
    this.service.getDashboard(this.startDate, this.endDate).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.data = data.message.data;
        this.filterData = this.data;
        for (const d of this.data) {
          let pu = _.findIndex(this.globals.users, {_id: d.respone});
          let pc = _.findIndex(this.globals.companies, {_id: d.company});
          let pb = _.findIndex(this.globals.brands, {_id: d.client});
          d.respone = this.globals.users[pu].name;
          d.client = this.globals.brands[pb].name;
          d.company = this.globals.companies[pc].name;
        }
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  edit(id: any) {
    this.globals.selectedCampaign = id;
    this.router.navigate(['/home/create']);
  }

  share(id: any) {
    window.open(`https://${window.location.host}/report/${id}/client`);
  }

  search() {
    const input = (document.getElementById('search') as HTMLInputElement).value;
    this.filterData = [];
    for (const d of this.data) {
      if (d.name.toLowerCase().match(input.toLowerCase()) || d.company.toLowerCase().match(input.toLowerCase()) || d.client.toLowerCase().match(input.toLowerCase()) || d.code.toLowerCase().match(input.toLowerCase()) || d.respone.toLowerCase().match(input.toLowerCase())) {
        this.filterData.push(d);
      }
    }
  }

}
