import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.scss']
})
export class AdminComponent implements OnInit {

  globals: any;
  section: any = 'users';

  constructor(globals: Globals, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
  }

  selectSection(section: any) {
    this.section = section;
  }

}
