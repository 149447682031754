<div class="text-center" *ngIf="globals.user">
    <div class="welcome">Hola, <span class="orange">{{ globals.user.name.split(' ')[0] }}</span> personaliza tu holograma</div>
</div>


<div class="bodyCreation">

    <div class="inputGroup" *ngIf="templates">
        <label class="labeler">Selecciona el template para tu Holograma</label>
        <select class="inputSelect" id="template" (change)="selectTemplate()">
            <option selected disabled>Seleccione</option>
            <option [value]="t._id" *ngFor="let t of templates">{{ t.name }}</option>
        </select>
    </div>

    <div *ngIf="this.globals.campaign.selectedTemplate && this.globals.campaign.selectedTemplate._id === '65bc16f285f6ec9dbb025e9d'">
        <div class="inputGroup" *ngIf="!this.c.selectedTemplate.artistImage || this.c.selectedTemplate.artistImage === ''">
            <label class="labeler">Sube la imagen principal de tu artista</label>
            <input type="file" class="inputSelect" id="fileArtist" (change)="uploadCreative('Artist')">
        </div>
        <div class="inputGroup" *ngIf="!this.c.selectedTemplate.song || this.c.selectedTemplate.song === ''">
            <label class="labeler">Sube la canción a reproducir</label>
            <input type="file" class="inputSelect" id="fileSong" (change)="uploadCreative('Song')">
        </div>
        <div class="inputGroup">
            <label class="labeler">Escribe el nombre de la canción a mostrar</label>
            <input type="text" class="inputSelect" placeholder="Nombre" id="namesong" (keyup)="setNameSong()">
        </div>
        <div class="inputGroup">
            <label class="labeler">Escribe el titúlo superior de tu Holograma</label>
            <input type="text" class="inputSelect" placeholder="Titúlo" id="title" (keyup)="setTitle()">
        </div>
    </div>

    <div *ngIf="this.globals.campaign.selectedTemplate && this.globals.campaign.selectedTemplate._id === '65c2607ed7189f3d7e00110c'">
        <div class="inputGroup" *ngIf="!this.c.selectedTemplate.artistImage || this.c.selectedTemplate.artistImage === ''">
            <label class="labeler">Sube la imagen principal de tu artista</label>
            <input type="file" class="inputSelect" id="fileArtist" (change)="uploadCreative('Artist')">
        </div>
        <div class="inputGroup" *ngIf="!this.c.selectedTemplate.song || this.c.selectedTemplate.song === ''">
            <label class="labeler">Sube la canción a reproducir</label>
            <input type="file" class="inputSelect" id="fileSong" (change)="uploadCreative('Song')">
        </div>
        <div class="inputGroup">
            <label class="labeler">Escribe el nombre de la canción a mostrar</label>
            <input type="text" class="inputSelect" placeholder="Nombre" id="namesong" (keyup)="setNameSong()">
        </div>
        <div class="inputGroup">
            <label class="labeler">Escribe el titúlo superior de tu Holograma</label>
            <input type="text" class="inputSelect" placeholder="Titúlo" id="title" (keyup)="setTitle()">
        </div>
    </div>

    <div *ngIf="this.globals.campaign.selectedTemplate && this.globals.campaign.selectedTemplate._id === '65c54a01446648e57ca38c21'">
        <div class="inputGroup" *ngIf="!this.c.selectedTemplate.artistImage || this.c.selectedTemplate.artistImage === ''">
            <label class="labeler">Sube la imagen de fondo</label>
            <input type="file" class="inputSelect" id="fileBackground" (change)="uploadCreative('Background')">
        </div>
        <div class="inputGroup">
            <label class="labeler">Escribe el titúlo superior de tu Holograma</label>
            <input type="text" class="inputSelect" placeholder="Titúlo" id="title" (keyup)="setTitle()">
        </div>
    </div>

</div>