<app-warnertemplate01 *ngIf="template === '65bc16f285f6ec9dbb025e9d'"></app-warnertemplate01>
<app-warnertemplate02 *ngIf="template === '65c2607ed7189f3d7e00110c'"></app-warnertemplate02>
<app-netflixtemplate01 *ngIf="template === '65c54a01446648e57ca38c21'"></app-netflixtemplate01>


<div class="customModal" *ngIf="globals.report">
    <div class="close" (click)="close()"><i class="lni lni-close"></i></div>
    <div class="report">
        <app-internalreport></app-internalreport>
    </div>
</div>