import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { NavbarService } from '../services/navbar.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  globals: any;

  constructor(globals: Globals, private service: NavbarService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
  }

  validate() {
    const nueva = (document.getElementById('new') as HTMLInputElement).value;
    const confirm = (document.getElementById('renew') as HTMLInputElement).value;
    if(nueva !== confirm) {
      return Swal.fire('', 'Las contraseñas no coinciden', 'error');
    } else {
      this.savePassword();
    }
  }

  
  savePassword() {
    this.globals.loading = true;
    const nueva = (document.getElementById('new') as HTMLInputElement).value;
    const old = (document.getElementById('before') as HTMLInputElement).value;
    this.service.putPassword({userId: localStorage.getItem('druuid'), newpassword: nueva, password: old}).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        return Swal.fire('', data.message.message, 'success');
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

}
