import { Globals } from 'src/app/globals';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import moment from 'moment-timezone';

@Component({
  selector: 'app-one',
  templateUrl: './one.component.html',
  styleUrls: ['./one.component.scss']
})
export class OneComponent implements OnInit {

  globals: any;
  billings: any;
  companies: any;
  brands: any;
  responses: any;
  selectedBrand: any;
  currency: any = 'USD';
  trm: any = 1;
  countries: any;
  regions: any;
  code: any;
  countering: any;
  nameCampaign: any;
  formats: any;
  selectedFormats: any = [];
  startDate: any;
  endDate: any;
  options: any = {
    alwaysShowCalendars: true,
    ranges: {
      'Hoy': [moment(), moment()],
      'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Ultimos 7 Días': [moment().subtract(6, 'days'), moment()],
      'Ultimos 30 Días': [moment().subtract(29, 'days'), moment()],
      'Este mes': [moment().startOf('month'), moment().endOf('month')],
      'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  };

  constructor(globals: Globals, private service: AdminService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.globals.userId = localStorage.getItem('druuid');
    this.getBilling();
    this.globals.campaign = {};
  }

  getBilling() {
    this.globals.loading = true;
    this.service.getBillingCompany().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.billings = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }


  selectBilling() {
    const input = (document.getElementById('billing') as HTMLInputElement).value;
    if (input !== '') {
      this.globals.campaign.billing = input;
      this.getCompaniesByUser();
    }
  }


  getCompaniesByUser() {
    this.globals.loading = true;
    this.service.getCompaniesByUser(this.globals.userId).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.companies = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }


  selectCompany() {
    const input = (document.getElementById('company') as HTMLInputElement).value;
    if (input !== '') {
      this.globals.campaign.company = input;
      this.getBrandsByCompany();
      this.getResponse(input);
    }
  }

  getBrandsByCompany() {
    const id = (document.getElementById('company') as HTMLInputElement).value;
    this.globals.loading = true;
    this.service.getBrandsByCompany(id).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.brands = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  selectBrand() {
    const input = (document.getElementById('brand') as HTMLInputElement).value;
    if (input !== '') {
      this.selectedBrand = input;
      this.globals.campaign.brand = input;
    }
  }

  getResponse(id: any) {
    const position = _.findIndex(this.companies, {_id: id});
    const users = this.companies[position].users;
    this.globals.loading = true;
    this.service.getUsersByCompany(users).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.responses = data.message.usersArray;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  selectResponse() {
    const input = (document.getElementById('response') as HTMLInputElement).value;
    if (input !== '') {
      this.globals.campaign.response = input;
      this.getCountries();
    }
  }

  getCountries() {
    this.globals.loading = true;
    this.service.getCountries().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.countries = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  selectCountry() {
    const country = (document.getElementById('country') as HTMLInputElement).value;
    if (country === '65b288b63d9cde7a1aa8ec1f') {
      this.currency = 'COP';
      this.trm = 4500;
    } else {
      this.currency = 'USD';
      this.trm = 1
    }
    this.globals.campaign.country = country;
    this.globals.campaign.currency = this.currency;
    this.globals.campaign.trm = this.trm;
    this.getFormats();
    this.generateCode();
  }

  getFormats() {
    this.globals.loading = true;
    this.service.getFormats().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.formats = data;
        for (const f of this.formats) {
          f.selected = false;
        }
        this.validateSave();
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }


  generateCode() {
    let country = (document.getElementById('country') as HTMLInputElement).value;
    let position = _.findIndex(this.countries, {_id: country});
    let date = new Date();
    let month = date.getMonth();
    let stringMonth = '';
    if (month <= 9) {
      stringMonth = `0${month + 1}`
    } else {
      stringMonth = `${month + 1}`
    }
    this.code = `24${this.countries[position].code}-${stringMonth}`;
    this.globals.campaign.code = this.code;
    this.validateSave();
  }

  counter() {
    this.countering = (document.getElementById('counter') as HTMLInputElement).value;
    this.globals.campaign.counter = this.countering;
    this.validateSave();
  }

  setName() {
    this.nameCampaign = (document.getElementById('name') as HTMLInputElement).value;
    this.globals.campaign.name = this.nameCampaign;
    this.validateSave();
  }

  selectFormat(format: any) {
    const position = _.findIndex(this.formats, {_id: format._id});
    const positionRemove = _.findIndex(this.selectedFormats, {_id: format._id});
    if (this.formats[position].selected) {
      this.formats[position].selected = false;
      this.selectedFormats.splice(positionRemove,1);
    } else {
      this.formats[position].selected = true;
      this.selectedFormats.push(this.formats[position]);
    }
    this.globals.campaign.formats =  this.selectedFormats;
    this.validateSave();
  }

  setBudget(id: any) {
    const budget = (document.getElementById(`budget${id}`) as HTMLInputElement).value;
    const position = _.findIndex(this.selectedFormats, {_id: id});
    this.selectedFormats[position].budget = parseInt(budget);
    this.setBid(id);
    this.validateSave();
  }

  setOrder(id: any) {
    const orderId = (document.getElementById(`orderId${id}`) as HTMLInputElement).value;
    const position = _.findIndex(this.selectedFormats, {_id: id});
    this.selectedFormats[position].orderId = orderId;
    this.validateSave();
  }

  setFee(id: any) {
    const fee = (document.getElementById(`feeId${id}`) as HTMLInputElement).value;
    const position = _.findIndex(this.selectedFormats, {_id: id});
    this.selectedFormats[position].feePercent = fee;
    this.validateSave();
  }


  setModel(id: any) {
    const model = (document.getElementById(`modelBuy${id}`) as HTMLInputElement).value;
    const position = _.findIndex(this.selectedFormats, {_id: id});
    this.selectedFormats[position].buyModel = model;
    this.validateSave();
  }

  setBid(id: any) {
    const bid = (document.getElementById(`bid${id}`) as HTMLInputElement).value;
    const position = _.findIndex(this.selectedFormats, {_id: id});
    this.selectedFormats[position].bid = bid;
    if (this.selectedFormats[position].buyModel === 'CPM') {
      this.selectedFormats[position].kpi = Math.round((this.selectedFormats[position].budget / parseFloat(bid)) * 1000);
    } else {
      this.selectedFormats[position].kpi = Math.round(this.selectedFormats[position].budget / parseFloat(bid));
    }
    this.validateSave();
  }

  selectedDate(value: any, id: any) {
    this.startDate = moment(value.start).format('YYYY-MM-DD');
    this.endDate = moment(value.end).format('YYYY-MM-DD');
    const range = moment(this.endDate).diff(this.startDate, 'days');
    const p = _.findIndex(this.selectedFormats, {_id: id});
    this.selectedFormats[p].diff = range;
    this.selectedFormats[p].startDate = this.startDate;
    this.selectedFormats[p].endDate = this.endDate;
    this.validateSave();
  }


  validateSave() {
    let g = this.globals.campaign;
    let formatos = [];
    if (g.formats) {
      for (const f of this.selectedFormats) {
        if (f.budget && f.feePercent && f.orderId) {
          formatos.push(f.id);
        }
      }
      if (formatos.length  === g.formats.length && g.kpi !== '' && g.bid !== '' && g.billing !== '' && g.buyModel !== '' && g.company !== '' && g.brand !== '' && g.response !== '' && g.country !== '' && g.name !== '' && g.code !== '') {
        g.readySaveOne = true;
      } else {
        g.readySaveOne = false;
      } 
    }
  }

}
