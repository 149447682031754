<div class="profile">
    <div class="portrait">
        <div class="image" attr.style="background-image: url({{ globals.user.picture }});"></div>
    </div>
    <div class="info">
        <div class="name">{{ globals.user.name }} <span class="role">{{ globals.user.role }}</span></div>
        <div class="email">{{ globals.user.email }}</div>
        <div class="title">Cambiar contraseña</div>
        <div class="form">
            <input class="input" placeholder="Contraseña actual" id="before">
            <input class="input" placeholder="Contraseña Nueva" id="new">
            <input class="input" placeholder="Confirmar contraseña nueva" id="renew">
            <div class="btnChange" (click)="savePassword()">Cambiar Contraseña</div>
        </div>
    </div>
</div>