<div class="text-center" *ngIf="globals.user">
    <div class="welcome">Hola, <span class="orange">{{ globals.user.name.split(' ')[0] }}</span> empecemos a configurar tu holograma</div>
</div>


<div class="bodyCreation">

    <div class="inputGroup" *ngIf="billings">
        <label class="labeler">Selecciona la compañia de facturación</label>
        <select class="inputSelect" id="billing" (change)="selectBilling()">
            <option selected disabled>Seleccione</option>
            <option [value]="b._id" *ngFor="let b of billings">{{ b.name }}</option>
        </select>
    </div>


    <div class="row">
        <div class="col-4">
            <div class="inputGroup" *ngIf="companies">
                <label class="labeler">Selecciona la compañia del holograma</label>
                <select class="inputSelect" id="company" (change)="selectCompany()">
                    <option selected disabled>Seleccione</option>
                    <option [value]="c._id" *ngFor="let c of companies">{{ c.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-4">
            <div class="inputGroup" *ngIf="brands">
                <label class="labeler">Selecciona la marca del holograma</label>
                <select class="inputSelect" id="brand" (change)="selectBrand()">
                    <option selected disabled>Seleccione</option>
                    <option [value]="b._id" *ngFor="let b of brands">{{ b.name }}</option>
                </select>
            </div>
        </div>
        <div class="col-4">
            <div class="inputGroup" *ngIf="selectedBrand">
                <label class="labeler">Selecciona el responsable del holograma</label>
                <select class="inputSelect" id="response" (change)="selectResponse()">
                    <option selected disabled>Seleccione</option>
                    <option [value]="r._id" *ngFor="let r of responses">{{ r.name }}</option>
                </select>
            </div>
        </div>
    </div>

    <div class="inputGroup" *ngIf="countries && countries.length > 0">
        <label class="labeler">Selecciona el país del holograma</label>
        <select class="inputSelect" id="country" (change)="selectCountry()">
            <option selected disabled>Seleccione</option>
            <option [value]="c._id" *ngFor="let c of countries">{{ c.name }}</option>
        </select>
    </div>

    
    <div class="row" *ngIf="formats">
        <div class="col-6">
            <div class="inputGroup">
                <label class="labeler">Nombre del holograma</label>
                <input class="inputSelect" placeholder="Nombre del holograma" id="name" (keyup)="setName()">
            </div>
        </div>
        <div class="col-6">
            <div class="inputGroup">
                <label class="labeler">contador del holograma</label>
                <input class="inputSelect" placeholder="contador" type="number" id="counter" (keyup)="counter()">
            </div>
        </div>
    </div>

    <div class="formats mt-2" *ngIf="formats">
        <div class="mintitle">¿Que Formatos vas a usar en la holograma?</div>
        <div class="row">
            <div class="col-lg-4 col-sm-12" *ngFor="let f of formats">
                <div class="cardFormat" [class.selected]="f.selected" attr.style="background-image: url({{f.backer}});">
                    <div class="icon" attr.style="background-image: url({{f.icon}});" *ngIf="f.name !== 'Youtube'"></div>
                    <div class="icon" attr.style="background-image: url({{f.icon}}); filter: invert(1);" *ngIf="f.name === 'Youtube'"></div>
                    <div class="name">{{ f.name }}</div>
                    <div class="mintitle">Fee de formato</div>
                    <input class="inputFormat" [class.disabled]="!f.selected" placeholder="Fee de formato" type="number" id="feeId{{f._id}}" (keyup)="setFee(f._id)">
                    <div class="mintitle">Presupuesto de formato</div>
                    <input class="inputFormat" [class.disabled]="!f.selected" placeholder="Presupuesto de formato" type="number" id="budget{{f._id}}" (keyup)="setBudget(f._id)">
                    <div class="message">valor ingresado: $ {{ f.budget | number }} {{ currency }}</div>
                    <div class="mintitle">Número de Orden</div>
                    <input class="inputFormat" [class.disabled]="!f.selected" placeholder="Número de Orden"  id="orderId{{f._id}}" (keyup)="setOrder(f._id)">
                    <div class="mintitle">Rango de fechas</div>
                    <input class="input" id="daterange" type="text" class="input" placeholder="AAAA - MM - DD // AAAA - MM - DD" name="daterangeInput" daterangepicker [options]="options" (selected)="selectedDate($event, f._id)" [class.disabled]="!f.selected"/>
                    <div class="mintitle">Modelo de compra</div>
                    <select id="modelBuy{{f._id}}" class="input" [class.disabled]="!f.selected" (change)="setModel(f._id)">
                        <option selected disabled>Seleccione</option>
                        <option value="CPM">CPM</option>
                        <option value="CPC">CPC</option>
                        <option value="CPE">CPE</option>
                        <option value="CPV">CPV</option>
                        <option value="CPA">CPA</option>
                    </select>
                    <div *ngIf="f.buyModel">
                        <div class="mintitle">{{ f.buyModel }}</div>
                        <input class="inputFormat" [class.disabled]="!f.selected" [placeholder]="f.buyModel"  id="bid{{f._id}}" (keyup)="setBid(f._id)">
                        <div class="message">
                            KPI: {{ f.kpi | number }}
                            <span *ngIf="f.buyModel === 'CPM'">Impresiones</span>
                            <span *ngIf="f.buyModel === 'CPC'">Clics</span>
                            <span *ngIf="f.buyModel === 'CPE'">Escuchas</span>
                            <span *ngIf="f.buyModel === 'CPV'">Views</span>
                            <span *ngIf="f.buyModel === 'CPA'">Acciones</span>
                        </div>
                    </div>
                    <div class="btnAdd" *ngIf="!f.selected" (click)="selectFormat(f)">Agregar</div>
                    <div class="btnRemove" *ngIf="f.selected" (click)="selectFormat(f)">Eliminar</div>
                </div>
            </div>
        </div>
    </div>
    

</div>