import { UsersComponent } from './../importables/users/users.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { AdminRoutingModule } from './admin-routing.module';
import { AdminComponent } from './admin.component';
import { CompaniesComponent } from '../importables/companies/companies.component';
import { BrandsComponent } from '../importables/brands/brands.component';


@NgModule({
  declarations: [
    AdminComponent,
    UsersComponent,
    CompaniesComponent,
    BrandsComponent
  ],
  imports: [
    CommonModule,
    AdminRoutingModule
  ]
})
export class AdminModule { }
