import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';
import { Workbook } from 'exceljs';
import * as fs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class AdminService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  getUsers() {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/users`, { headers });
  }

  upload(body) {
    const headers = this.auth.getToken();
    return this.http.post(`https://upload.containermedia.com.co/api/upload/file?token=86CSKjWRBQp1KzsDgCTvvG_h3YcBjzTWiV8FgVHmh1Ejf`, body, { headers });
  }

  delete(token: any, route: any, file: any) {
    const headers = this.auth.getToken();
    return this.http.delete(`https://upload.containermedia.com.co/api/upload/file?token=${token}&route=${route}&fileName=${file}`, { headers });
  }

  createUser(body: any) {
    const headers = this.auth.getToken();
    return this.http.post(`${environment.urlApi}/users/user`, body, { headers });
  }

  deleteUser(id: any) {
    const headers = this.auth.getToken();
    return this.http.delete(`${environment.urlApi}/users/user?id=${id}`, { headers });
  }


  getCompanies() {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/companies`, { headers });
  }

  getBillingCompany() {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/bills`, { headers });
  }

  getCompaniesByUser(id: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/companies/company?id=${id}`, { headers });
  }

  createCompanie(body: any) {
    const headers = this.auth.getToken();
    return this.http.post(`${environment.urlApi}/companies/company`, body, { headers });
  }

  editCompany(body: any) {
    const headers = this.auth.getToken();
    return this.http.put(`${environment.urlApi}/companies/company`, body, { headers });
  }

  getBrands() {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/brands`, { headers });
  }

  getBrandsByCompany(id: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/brands/brand?id=${id}`, { headers });
  }

  getCountries() {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/countries/countries`, { headers });
  }

  getFormats() {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/formats`, { headers });
  }

  getUsersByCompany(users: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/users/user/company?users=${users}`, { headers });
  }

  saveStepOne(body: any) {
    const headers = this.auth.getToken();
    return this.http.post(`${environment.urlApi}/campaigns/one`, body, { headers });
  }

  saveStepTwo(body: any) {
    const headers = this.auth.getToken();
    return this.http.post(`${environment.urlApi}/campaigns/two`, body, { headers });
  }

  getCampaign(id: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/campaigns/campaign?id=${id}`, { headers });
  }

  getRegions(id: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/countries/regions?country=${id}`, { headers });
  }

  getTemplates() {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/templates`, { headers });
  }

  saveStepThree(body: any) {
    const headers = this.auth.getToken();
    return this.http.post(`${environment.urlApi}/campaigns/three`, body, { headers });
  }

  
  getReport(id: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/campaigns/report?id=${id}`, { headers });
  }

  postReport(body: any) {
    const headers = this.auth.getToken();
    return this.http.post(`${environment.urlApi}/campaigns/report`, body, { headers });
  }

  getDashboard(startDate: any, endDate: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/dashboard?startDate=${startDate}&endDate=${endDate}`, { headers });
  }

  saveXlsx(excelData: any) {
    console.log(excelData.data);
    const title = excelData.title;
    const data = excelData.data;
    let workbook = new Workbook();
    let general = workbook.addWorksheet('Generla');
    let byDay = workbook.addWorksheet('Diaria');
    let byDayOfTheWeek = workbook.addWorksheet('Dia de la semana');
    let creatives = workbook.addWorksheet('Creativos');
    let regions = workbook.addWorksheet('Regiones');
    let devices = workbook.addWorksheet('Dispositivos');
    let operatives = workbook.addWorksheet('Sistemas Operativos');

    general.addRow(['Presupuesto']);
    general.addRow([ data.general.budget]);
    for (const d of data.general.data) {
      general.addRow([ d.name]);
      general.addRow([ d.value]);
    }

    byDay.addRow(['Fecha', 'Impresiones', 'Clicks', 'CTR', 'Inversión']);
    for (const d of data.perDays) {
      byDay.addRow([ d.day, d.imps, d.clicks, d.ctr, d.cost]);
    }

    byDayOfTheWeek.addRow(['Día', 'Impresiones', 'Clicks', 'CTR']);
    for (const d of data.dayOfWeeks) {
      byDayOfTheWeek.addRow([ d.dayOfWeek, d.imps, d.clicks, d.ctr, d.cost]);
    }

    creatives.addRow(['id', 'Impresiones', 'Clicks', 'CTR']);
    for (const d of data.creatives) {
      creatives.addRow([ d.id, d.imps, d.clicks, d.ctr, d.cost]);
    }

    regions.addRow(['Región', 'Impresiones', 'Clicks', 'CTR']);
    for (const d of data.dayOfWeeks) {
      regions.addRow([ d.region, d.imps, d.clicks, d.ctr, d.cost]);
    }
    
    devices.addRow(['Dispositivo', 'Impresiones', 'Clicks']);
    for (const d of data.devices) {
      devices.addRow([ d.name, d.imps, d.clicks]);
    }
    
    operatives.addRow(['Sistema Operativo', 'Impresiones', 'Clicks']);
    for (const d of data.os) {
      operatives.addRow([ d.name, d.imps, d.clicks]);
    }

    workbook.xlsx.writeBuffer().then((data) => {
      let blob = new Blob([data], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      fs.saveAs(blob, title + '.xlsx');
    });
  }
}
