import { Globals } from 'src/app/globals';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit {

  globals: any;
  users: any;
  selectedImage: any = '';

  constructor(globals: Globals, private service: AdminService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  getUserInfo() {
    this.globals.loading = true;
    this.service.getUsers().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.users = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  uploadImage() {
    let file = (document.getElementById('image') as HTMLInputElement).files[0];
    this.globals.loading = true;
    var fd = new FormData();
    fd.append('fileName', file);
    fd.append('route', 'dataroom');
    this.service.upload(fd).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.selectedImage = data.message.root;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  deleteImage() {
    const token = '86CSKjWRBQp1KzsDgCTvvG_h3YcBjzTWiV8FgVHmh1Ejf';
    const route = 'dataroom';
    const file = this.selectedImage.split('dataroom/')[1];
    this.service.delete(token, route, file).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.selectedImage = '';
        (document.getElementById('image') as HTMLInputElement).value = null;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  createUser() {
    let name = (document.getElementById('name') as HTMLInputElement).value;
    let email = (document.getElementById('email') as HTMLInputElement).value;
    let password = (document.getElementById('password') as HTMLInputElement).value;
    let role = (document.getElementById('role') as HTMLInputElement).value;
    if (name !== '' && email !== '' && password !== '' && role !== '' && this.selectedImage !== '') {
      this.service.createUser({name, email, password, role, picture: this.selectedImage}).subscribe((data: any) => {
        if (data.success === 0) {
          Swal.fire('', data.message.message, 'error');
          this.globals.loading = false;
        } else if(data.success === 2) {
          localStorage.removeItem('token');
          localStorage.removeItem('druuid');
          this.router.navigate(['']);
        } else {
          this.globals.loading = false;
          this.getUserInfo();
        }
      }, err => {
        this.globals.loading = false;
        return Swal.fire('', err.message, 'error');
      });      
    } else {
      Swal.fire('', 'Debe rellenar todos los campos', 'error');
    }
  }

  deleteUser(id: any) {
    Swal.fire({
      title: "¿Esta Seguro de querer eliminar este usuario?",
      showCancelButton: true,
      confirmButtonText: "Eliminar",
    }).then((result) => {
      if (result.isConfirmed) {
        this.service.deleteUser(id).subscribe((data: any) => {
          if (data.success === 0) {
            Swal.fire('', data.message.message, 'error');
            this.globals.loading = false;
          } else if(data.success === 2) {
            localStorage.removeItem('token');
            localStorage.removeItem('druuid');
            this.router.navigate(['']);
          } else {
            this.globals.loading = false;
            this.getUserInfo();
          }
        }, err => {
          this.globals.loading = false;
          return Swal.fire('', err.message, 'error');
        });    
      } else if (result.isDenied) {
      }
    });
  }

}
