import { Component } from '@angular/core';
import { Globals } from './globals';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {

  title = 'H O L O G R A M';
  globals: any;

  constructor(globals: Globals) {
    this.globals = globals;
  }

}
