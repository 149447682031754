<div class="text-center" *ngIf="globals.user">
    <div class="welcome">Hola, <span class="orange">{{ globals.user.name.split(' ')[0] }}</span> alimentemos de datos tu holograma</div>
</div>

<input id="ultraPaste" style="opacity: 0; position: fixed; bottom: 0;" (change)="ultraPaste()">

<div class="format" *ngFor="let f of globals.campaign.formats">
    <div class="header">
        <!-- <span class="budget2">($ {{ f.budget | number }} {{ g.currency }})</span> -->
        <div class="icon" attr.style="background-image: url({{ f.icon }});" *ngIf="f.name !== 'Youtube'"></div>
        <div class="icon" attr.style="background-image: url({{ f.icon }}); filter: invert(1);" *ngIf="f.name === 'Youtube'"></div>
        <div class="budget">$ {{ f.realBudget | number }} <span class="cu">USD</span></div>
    </div>

    <div class="bodyData">

        <div class="dfTitle">
            <div class="title">Añadir datos y segmentación por día</div>
            <div class="btnAdd" (click)="addDay(f._id)"><i class="lni lni-circle-plus"></i></div>
        </div>

        <div class="dayData">

            <div class="mineTable">
                <div class="tableHead">
                    <div class="title text-truncate" style="min-width: 130px;" (dblclick)="enableUltraPaste(f._id, 'date')">Fecha</div>
                    <div class="title text-truncate" *ngFor="let k of f.keys" (dblclick)="enableUltraPaste(f._id, k.id)">{{ k.name }} <span *ngIf="k.type === 'cash'"> - USD</span></div>
                    <div class="title text-truncate">Segmentación</div>
                </div>
                <div class="tableBody">
                    <div class="tableSet" *ngFor="let d of f.days">
                        <input class="inputSet" id="date{{d.id}}" type="date" style="min-width: 130px;">
                        <input class="inputSet" type="number" id="dayData{{f._id}}{{d.counter}}{{k.id}}" [placeholder]="k.name" *ngFor="let k of f.keys" value="0">

                        <div class="btnSegm" data-bs-toggle="modal" data-bs-target="#modalSegment" (click)="addSegment(f._id, d.counter)" *ngIf="!d.segment"><i class="lni lni-circle-plus"></i></div>
                        <div class="btnSegm" data-bs-toggle="modal" data-bs-target="#modalSegment" (click)="editSegment(f._id, d.counter)" *ngIf="d.segment"><i class="lni lni-pencil"></i></div>

                    </div>
                </div>
            </div>

        </div>

        <div class="dfTitle">
            <div class="title">Añadir datos de creativo por día</div>
            <div class="btnAdd" (click)="addCreative(f._id)"><i class="lni lni-add-files"></i></div>
        </div>

        <input id="ultraCreativePaste" style="opacity: 0; position: fixed; bottom: 0;" (change)="CreativeultraPaste()">

        <div class="row creatives" *ngFor="let c of f.creatives">
            <div class="col-3">
                <div>
                    <div class="delteCreative" (click)="removeCreative(f._id, c.id)" *ngIf="globals.campaign.state === 1 || c.file === ''">Eliminar Creativo</div>

                    <div *ngIf="f._id !== '65c0f8d4b224b7b9a04e2ef4'">
                        <input type="file" class="input" id="creativeInput{{c.id}}" *ngIf="c.file === ''" (change)="uploadCreative(f._id, c.id)">
                        <div class="creative" attr.style="background-image: url({{c.file}});" *ngIf="c.type === 'image'">
                            <div class="delete" (click)="deleteFile(f._id, c.id)" *ngIf="globals.campaign.state === 1 || c.file === ''"><i class="lni lni-remove-file"></i></div>
                        </div>
                        <div class="creative" *ngIf="c.type === 'video'">
                            <video [src]="c.file" controls></video>
                        </div>
                        <div class="creative" *ngIf="c.type === 'audio'">
                            <audio [src]="c.file" controls></audio>
                        </div>
                    </div>

                    <div class="tag" *ngIf="f._id === '65c0f8d4b224b7b9a04e2ef4'">
                        <textarea id="tagCreative{{c.id}}" [value]="c.file" (change)="setTag(f._id, c.id)"></textarea>
                        <div id="prevCreative{{c.id}}"></div>
                    </div>

                </div>
            </div>
            <div class="col-9">
                <div class="mineTable">
                    <div class="tableHead">
                        <div class="title text-truncate" style="min-width: 130px;" (dblclick)="enableCreativeUltraPaste(f._id, c.id, 'date', c.selector)">Fecha</div>
                        <div class="title text-truncate" *ngFor="let k of f.keys" (dblclick)="enableCreativeUltraPaste(f._id, c.id, k.id, c.selector)">{{ k.name }} <span *ngIf="k.type === 'cash'"> - USD</span></div>
                    </div>
                    <div class="tableBody">
                        <div class="tableSet" *ngFor="let d of f.days">
                            <input class="inputSet" id="creativeDate{{d.id}}{{c.selector}}" type="date" style="min-width: 130px;">
                            <input class="inputSet" type="number" id="creative{{d.id}}{{c.selector}}{{k.id}}" [placeholder]="k.name" *ngFor="let k of f.keys" value="0">
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="dfTitle mt-4">
            <div class="title">Añadir Prints</div>
            <div class="btnAdd" (click)="addPrints(f._id)"><i class="lni lni-add-files"></i></div>
        </div>

        <div class="row creatives" *ngFor="let c of f.prints">
            <div class="col-12">
                <div>
                    <div class="delteCreative" (click)="removePrint(f._id, c.id)" *ngIf="globals.campaign.state === 1 || c.file === ''">Eliminar Print</div>
                    <input type="file" class="input" id="printInput{{c.id}}" *ngIf="c.file === ''" (change)="uploadPrint(f._id, c.id)">
                    <div class="creative" attr.style="background-image: url({{c.file}});" *ngIf="c.type === 'image'">
                        <div class="delete" (click)="deleteFile(f._id, c.id)" *ngIf="globals.campaign.state === 1 || c.file === ''"><i class="lni lni-remove-file"></i></div>
                    </div>
                    <div class="creative" *ngIf="c.type === 'video'">
                        <video [src]="c.file" controls></video>
                    </div>
                </div>
            </div>
        </div>
        

        <div class="dfTitle mt-4">
            <div class="title">Añadir Comentarios</div>
        </div>

        <div class="row creatives">
            <div class="col-12">
                <div>
                    <textarea class="textArea" id="textAreaFormat{{f._id}}"></textarea>
                </div>
            </div>
        </div>

</div>



<!-- Modal -->
<div class="modal fade" id="modalSegment" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1" aria-labelledby="modalSegmentLabel" aria-hidden="true">
    <div class="close" data-bs-dismiss="modal"><i class="lni lni-close"></i></div>
    <div class="modal-dialog custom">
        <div class="modal-content">
            <div class="title">Añadir segmentación por edad y género<span *ngIf="sd"> - {{ sd.date }}</span></div>
            <div class="genderAge">
                <div class="male">
                    <div class="setValue" *ngFor="let a of agesGender">
                        <div class="title">{{ a.name }}</div>
                        <div class="iner">
                            <input class="input" type="number" id="male{{a.id}}" min="0" max="100" [value]="a.value" (change)="setValue('male', a.id)" (keyup)="setValue('male', a.id)">
                        </div>
                        <div class="progress" role="progressbar" attr.aria-label="male{{a.id}}, " attr.aria-valuenow="{{ a.value }}" aria-valuemin="0" aria-valuemax="100" id="ariaValueTopMale{{a.id}}">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" attr.style="width: {{ a.value }}%" id="ariaValueMale{{a.id}}">{{ a.value }}%</div>
                        </div>
                    </div>
                    <div class="set">HOMBRES - <span [class.red]="maleCounter + femaleCounter > 100 || maleCounter + femaleCounter < 100">{{ maleCounter }}</span>%</div>
                </div>
                <div class="female">
                    <div class="setValue" *ngFor="let a of agesGender">
                        <div class="progress" role="progressbar" attr.aria-label="female{{a.id}}" attr.aria-valuenow="{{ a.value }}" aria-valuemin="0" aria-valuemax="100" id="ariaValueTopFemale{{a.id}}">
                            <div class="progress-bar progress-bar-striped progress-bar-animated" attr.style="width: {{ a.value }}%" id="ariaValueFemale{{a.id}}">{{ a.value }}%</div>
                        </div>
                        <div class="iner">
                            <input class="input" type="number" id="female{{a.id}}" min="0" max="100" [value]="a.value" (change)="setValue('female', a.id)" (keyup)="setValue('female', a.id)">
                        </div>
                        <div class="title">{{ a.name }}</div>
                    </div>
                    <div class="set">MUJERES - <span [class.red]="maleCounter + femaleCounter > 100 || maleCounter + femaleCounter < 100">{{ femaleCounter }}</span>%</div>
                </div>
            </div>

            <div class="title mt-2">Añadir segmentación por Región<span *ngIf="sd"> - {{ sd.date }}</span></div>
            <div class="geo">
                <div class="dataRegion">
                    <div class="mineTable">
                        <div class="tableHead">
                            <div class="title text-truncate">Región</div>
                            <div class="title text-truncate" *ngFor="let k of sk" [class.red]="regionsKeyCounter[k.id] > 100">{{ k.name }} - {{ regionsKeyCounter[k.id] }}%</div>
                        </div>
                        <div class="tableBody">
                            <div class="tableSet" *ngFor="let r of g.regions">
                                <input class="inputSet" type="text" [value]="r.name" disabled>
                                <input class="inputSet" type="number" id="region{{r._id}}{{k.id}}" [placeholder]="k.name" value="0" *ngFor="let k of sk" (change)="calulateRegionsbyKey()">
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="title mt-4">Añadir segmentación por Dispositivo<span *ngIf="sd"> - {{ sd.date }}</span></div>
            <div class="devices">
                <div class="mineTable">
                    <div class="tableHead">
                        <div class="title text-truncate">Dispositivo</div>
                        <div class="title text-truncate" *ngFor="let k of sk" [class.red]="devicesKeyCounter[k.id] > 100">{{ k.name }} - {{ devicesKeyCounter[k.id] }} %</div>
                    </div>
                    <div class="tableBody">
                        <div class="tableSet" *ngFor="let d of devices">
                            <input class="inputSet" type="text" [value]="d.name" disabled>
                            <input class="inputSet" type="number" id="device{{d.id}}{{k.id}}" [placeholder]="k.name" value="0" *ngFor="let k of sk" (change)="calculateDevices()">
                        </div>
                    </div>
                </div>
            </div>

            
            <div class="title mt-4">Añadir segmentación por Sistema Operativo<span *ngIf="sd"> - {{ sd.date }}</span></div>
            <div class="devices">
                <div class="mineTable">
                    <div class="tableHead">
                        <div class="title text-truncate">Sistema Operativo</div>
                        <div class="title text-truncate" *ngFor="let k of sk" [class.red]="osKeyCounter[k.id] > 100">{{ k.name }} - {{ osKeyCounter[k.id] }} %</div>
                    </div>
                    <div class="tableBody">
                        <div class="tableSet" *ngFor="let d of operatives">
                            <input class="inputSet" type="text" [value]="d.name" disabled>
                            <input class="inputSet" type="number" id="os{{d.id}}{{k.id}}" [placeholder]="k.name" value="0" *ngFor="let k of sk" (change)="calculateOs()">
                        </div>
                    </div>
                </div>
            </div>


            <div class="saveSegment" (click)="buildSegmentData()" data-bs-dismiss="modal">Guardar Segmentación</div>

        </div>
    </div>
</div>