import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Daterangepicker } from 'ng2-daterangepicker';
import { FormsModule } from '@angular/forms';

import { PanelRoutingModule } from './panel-routing.module';
import { PanelComponent } from './panel.component';


@NgModule({
  declarations: [PanelComponent],
  imports: [
    CommonModule,
    PanelRoutingModule,
    Daterangepicker,
    FormsModule
  ]
})
export class PanelModule { }
