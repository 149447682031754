<div class="bodyPanel" *ngIf="data">
    <div class="welcome">Hola, <span class="orange">{{ globals.user.name.split(' ')[0] }}</span> Bienvenido a Hologram </div>
    <div id="accordionCampaigns">
        <div class="filters">
            <div class="search">
                <input class="input" placeholder="Buscar" placeholder="Search" id="search" (keyup)="search()">
            </div>
            <div class="date">
                <input class="input" id="daterange" type="text" class="input" placeholder="AAAA - MM - DD // AAAA - MM - DD" name="daterangeInput" daterangepicker [options]="options" (selected)="selectedDate($event)"/>
            </div>
        </div>
        <div class="campaign accordion-item" *ngFor="let d of filterData">
            <div class="header" data-bs-toggle="collapse" attr.data-bs-target="#collapseCampaign{{d.id}}" aria-expanded="true" attr.aria-controls="collapseCampaign{{d.id}}">
                <div class="colorState green stateAnimation" *ngIf="d.formats[0].lastUpdate"></div>
                <div class="colorState gray" *ngIf="!d.formats[0].lastUpdate"></div>
                <div class="ten">
                    <div class="mintile text-truncate">Responsable</div>
                    {{ d.respone }}
                </div>
                <div class="ten">
                    <div class="mintile text-truncate">Cliente</div>
                    {{ d.company }}
                </div>
                <div class="ten">
                    <div class="mintile text-truncate">Anunciante</div>
                    {{ d.client }}
                </div>
                <div class="ten">
                    <div class="mintile text-truncate">Código</div>
                    {{ d.code }}
                </div>
                <div class="name">
                    <div class="mintile text-truncate">Nombre</div>
                    {{ d.name }}
                </div>
                <div class="actions">
                    <div class="btner" (click)="edit(d.id)"><i class="lni lni-pencil"></i></div>
                    <div class="btner" (click)="edit(d.id)"><i class="lni lni-brush"></i></div>
                    <div class="btner" (click)="share(d.id)"><i class="lni lni-eye"></i></div>
                </div>
            </div>
            <div class="accordion-collapse collapse" id="collapseCampaign{{d.id}}" data-bs-parent="#accordionCampaigns">
                <div class="body">

                    <div class="format" *ngFor="let f of d.formats">

                        <div class="left">
                            <div class="top">
                                <div class="section">
                                    <div class="title text-truncate">Formato</div>
                                    <div class="value">{{ f.name }} - {{ d.name }}</div>
                                </div>
                                <div class="section">
                                    <div class="title text-truncate">Impresiones</div>
                                    <div class="value">{{ f.imps | number }}</div>
                                </div>
                                <div class="section">
                                    <div class="title text-truncate">Clicks</div>
                                    <div class="value">{{ f.clicks | number }}</div>
                                </div>
                                <div class="section">
                                    <div class="title text-truncate">CTR</div>
                                    <div class="value">{{ f.ctr }}%</div>
                                </div>
                                <div class="section">
                                    <div class="title text-truncate">KPI - 
                                        <span *ngIf="f.buyModel === 'CPM'">Impresiones</span>
                                        <span *ngIf="f.buyModel === 'CPC'">Clics</span>
                                        <span *ngIf="f.buyModel === 'CPE'">Escuchas</span>
                                        <span *ngIf="f.buyModel === 'CPV'">Views</span>
                                        <span *ngIf="f.buyModel === 'CPA'">Conversiones</span>
                                    </div>
                                    <div class="value">{{ f.kpi | number }}</div>
                                </div>
                            </div>
                            <div class="bottom">
                                <div class="invest">
                                    <div class="p10">
                                        <div class="title text-truncate">Invertido</div>
                                        <div class="value"><span class="spend">${{ f.spend | number }} USD</span> de ${{ f.budget | number }} USD</div>
                                        <div class="progress" role="progressbar" attr.aria-valuenow="{{ f.percentSpend }}" aria-valuemin="0" aria-valuemax="100">
                                            <div class="progress-bar" attr.style="width: {{ f.percentSpend }}%" *ngIf="f.percentSpend <= 100"></div>
                                            <div class="progress-bar" attr.style="width: 100%" *ngIf="f.percentSpend > 100"></div>
                                        </div>
                                        <div class="message">RES: ${{ f.leftSpend | number }} USD</div>
                                    </div>
                                </div>
                                <div class="section2">
                                    <div class="progress2" role="progressbar" attr.aria-valuenow="{{ f.percentNowKpi }}" aria-valuemin="0" aria-valuemax="100">
                                        <div class="progress-bar" attr.style="width: {{ f.percentNowKpi }}%" *ngIf="f.percentNowKpi <= 100">Cumplimiento - {{ f.percentNowKpi }}%</div>
                                        <div class="progress-bar" attr.style="width: 100%" *ngIf="f.percentNowKpi > 100">Cumplimiento - {{ f.percentNowKpi }}%</div>
                                    </div>
                                    <div class="balances">
                                        <div class="balance">
                                            <div class="title text-truncate">
                                                <span *ngIf="f.buyModel === 'CPM'">Impresiones</span>
                                                <span *ngIf="f.buyModel === 'CPC'">Clics</span>
                                                <span *ngIf="f.buyModel === 'CPE'">Escuchas</span>
                                                <span *ngIf="f.buyModel === 'CPV'">Views</span>
                                                <span *ngIf="f.buyModel === 'CPA'">Conversiones</span>
                                                Estimado Diario
                                            </div>
                                            <div class="value">{{ f.dailyKpi | number }}</div>
                                        </div>
                                        <div class="balance">
                                            <div class="title text-truncate">
                                                <span *ngIf="f.buyModel === 'CPM'">CPM</span>
                                                <span *ngIf="f.buyModel === 'CPC'">CPC</span>
                                                <span *ngIf="f.buyModel === 'CPE'">CPE</span>
                                                <span *ngIf="f.buyModel === 'CPV'">CPV</span>
                                                <span *ngIf="f.buyModel === 'CPA'">CPA</span>
                                                Ideal
                                            </div>
                                            <div class="value">${{ f.bid | number }} USD</div>
                                        </div>
                                        <div class="balance bn">
                                            <div class="title text-truncate">Presupuesto Estimado Diario</div>
                                            <div class="value">${{ f.dailyBudget | number }} USD</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="section">
                                    <div class="balances">
                                        <div class="balance">
                                            <div class="title text-truncate">Rango de fechas:</div>
                                            <div class="value">{{ f.startDate }} <br> {{ f.endDate }}</div>
                                        </div>
                                        <div class="balance">
                                            <div class="title text-truncate">Días:</div>
                                            <div class="value">{{ f.diff }}</div>
                                        </div>
                                        <div class="balance">
                                            <div class="title text-truncate">Días Restantes:</div>
                                            <div class="value">{{ f.daysLeft }}</div>
                                        </div>
                                        <div class="balance bn">
                                            <div class="title text-truncate">Actualizado:</div>
                                            <div class="value">{{ f.lastUpdate }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="rigth">
                            <div class="letGauge">
                                <div class="gauge" [class.red]="f.gaugeColor === 1" [class.yellow]="f.gaugeColor === 2" [class.green]="f.gaugeColor === 3" style="width: 200px; --rotation: {{ f.percentNowSpend }}deg;">
                                    <div class="percentage"></div>
                                    <div class="mask"></div>
                                    <span class="value">$ {{ f.percentNowSpendText }}%
                                    </span>
                                </div>
                            </div>
                            <div class="kpiBar">
                                <div class="progress" role="progressbar" attr.aria-valuenow="{{ f.percentNowKpi }}" aria-valuemin="0" aria-valuemax="100">
                                    <div class="progress-bar" [class.red]="f.barColor === 1" [class.yellow]="f.barColor === 2" [class.green]="f.barColor === 3" attr.style="width: {{f.percentNowKpi}}%"></div>
                                </div>
                                <div class="lineIdeal" attr.style="margin-left: {{ f.idealPercentKpi }}%;"></div>
                                <div class="value" attr.style="margin-left: {{ f.idealPercentKpi }}%;" *ngIf="f.idealPercentKpi < 80">Entrega ideal: {{ f.idealImps | number }} <span *ngIf="f.buyModel === 'CPM'">Impresiones</span><span *ngIf="f.buyModel === 'CPC'">Clics</span><span *ngIf="f.buyModel === 'CPE'">Escuchas</span><span *ngIf="f.buyModel === 'CPV'">Views</span><span *ngIf="f.buyModel === 'CPA'">Conversiones</span></div>
                                <div class="value" *ngIf="f.idealPercentKpi > 80">Entrega ideal: {{ f.idealImps | number }} <span *ngIf="f.buyModel === 'CPM'">Impresiones</span><span *ngIf="f.buyModel === 'CPC'">Clics</span><span *ngIf="f.buyModel === 'CPE'">Escuchas</span><span *ngIf="f.buyModel === 'CPV'">Views</span><span *ngIf="f.buyModel === 'CPA'">Conversiones</span></div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
    </div>
</div>