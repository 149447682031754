import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import Swal from 'sweetalert2';
import { Router } from '@angular/router';
import { LoginService } from '../services/login.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  globals: any;

  constructor(private service: LoginService, private router: Router, globals: Globals) {
    this.globals = globals;
  }

  ngOnInit(): void {
  }

  
  login() {
    this.globals.loading = true;
    const email = (document.getElementById('mail') as HTMLInputElement ).value;
    const password = (document.getElementById('pass') as HTMLInputElement ).value;
    this.service.login({email, password }).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.message === 'TOKEN') {
        localStorage.removeItem('token');
      } else {
        this.globals.loading = false;
        localStorage.setItem('token', data.message.token);
        localStorage.setItem('druuid', data.message.userID);
        this.router.navigate(['home']);
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  view() {
    const password = (document.getElementById('pass') as HTMLInputElement);
    const value = password.nodeValue;
    if (password[`type`] === 'password') {
      password[`type`] = 'text';
    } else {
      password[`type`] = 'password';
    }
    password.nodeValue = value;
  }

}
