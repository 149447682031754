<div class="body">
    <div class="header">
        <div class="search">
            <input class="input" placeholder="Buscar" id="search">
        </div>
        <div class="btnAdd text-truncate" data-bs-toggle="modal" data-bs-target="#modal">+ Agregar</div>
    </div>

    <div class="title">Marcas <span class="badge">{{ brands.length }}</span></div>

    <div class="lister">
        <table class="table">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Logo</th>
                <th scope="col">Icono</th>
                <th scope="col">Logo Horizontal</th>
                <th scope="col">Colores</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let b of brands">
                <td>{{ b.name }}</td>
                <td><img class="logos" [src]="b.logo"></td>
                <td><img class="logos" [src]="b.icon"></td>
                <td><img class="letter" [src]="b.letter"></td>
                <td>
                  <div class="colors">
                    <div class="color" attr.style="background-color: #{{ c }};" *ngFor="let c of b.colors"></div>
                  </div>
                </td>
                <td>
                  <i class="lni lni-pencil" data-bs-toggle="modal" data-bs-target="#modal"></i>
                </td>
              </tr>
            </tbody>
          </table>
    </div>

</div>


<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
            <div class="title">Añadir Marca</div>
        </div>
      </div>
    </div>
  </div>