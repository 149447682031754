import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { AdminService } from '../../services/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import moment from 'moment-timezone';
import * as am4core from "@amcharts/amcharts4/core";
import * as am4charts from "@amcharts/amcharts4/charts";
import am4themes_animated from "@amcharts/amcharts4/themes/animated";
import * as am4maps from "@amcharts/amcharts4/maps";

am4core.useTheme(am4themes_animated);
am4core.addLicense("CH410755505");


@Component({
  selector: 'app-internalreport',
  templateUrl: './internalreport.component.html',
  styleUrls: ['./internalreport.component.scss']
})
export class InternalreportComponent implements OnInit {

  globals: any;
  data: any;
  startDate: any = moment().startOf('month').format('YYYY-MM-DD');
  endDate: any = moment().endOf('month').format('YYYY-MM-DD');
  selectedCreative: any = '';
  options: any = {
    alwaysShowCalendars: true,
    ranges: {
      'Hoy': [moment(), moment()],
      'Ayer': [moment().subtract(1, 'days'), moment().subtract(1, 'days')],
      'Ultimos 7 Días': [moment().subtract(6, 'days'), moment()],
      'Ultimos 30 Días': [moment().subtract(29, 'days'), moment()],
      'Este mes': [moment().startOf('month'), moment().endOf('month')],
      'Mes pasado': [moment().subtract(1, 'month').startOf('month'), moment().subtract(1, 'month').endOf('month')]
    }
  };
  creativeType: any = 'image';

  constructor(private service: AdminService, globals: Globals, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.getReport();
  }

  getReport() {
    this.globals.loading = true;
    this.service.postReport({id: this.globals.idReport, _id: [this.globals.selectedReport.id], startDate: this.globals.selectedReport.st, endDate: this.globals.selectedReport.ed}).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.data = data;
        this.draw();
        if (this.globals.reportVersion !== 'client') {
          let position;
          for (const g of this.data.general.data) {
            if (g.id === 'cost') {
              position = _.findIndex(this.data.general.data, {id: g.id});
              this.data.general.data.splice(position, 1);
            }
          }
          for (const g of this.data.general.data) {
            if (g.id === 'cpc') {
              position = _.findIndex(this.data.general.data, {id: g.id});
              this.data.general.data.splice(position, 1);
            }
          }
          for (const g of this.data.general.data) {
            if (g.id === 'cpm') {
              position = _.findIndex(this.data.general.data, {id: g.id});
              this.data.general.data.splice(position, 1);
            }
          }
          for (const g of this.data.general.data) {
            if (g.id === 'cpe') {
              position = _.findIndex(this.data.general.data, {id: g.id});
              this.data.general.data.splice(position, 1);
            }
          }
          for (const g of this.data.general.data) {
            if (g.id === 'cpv') {
              position = _.findIndex(this.data.general.data, {id: g.id});
              this.data.general.data.splice(position, 1);
            }
          }
          for (const g of this.data.general.data) {
            if (g.id === 'cpa') {
              position = _.findIndex(this.data.general.data, {id: g.id});
              this.data.general.data.splice(position, 1);
            }
          }
        }
        if (data.creatives) {
          setTimeout(() => {
            this.selectedCreative = data.creatives[0].file;
            if (data.creatives[0].type === 'tag') {
              this.viewCreative(data.creatives[0]);
            }
            if (data.creatives[0].type === 'audio') {
              this.creativeType = 'audio';
            }
          }, 1000);
        }
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  selectedDate(value: any) {
    this.globals.selectedReport.st = moment(value.start).format('YYYY-MM-DD');
    this.globals.selectedReport.ed = moment(value.end).format('YYYY-MM-DD');
    this.getReport();
  }

  draw() {
    this.globals.draw = true;
    setTimeout(() => {
      this.drawDay();
      this.drawWeek();
      if (this.data.genderAge.length > 0) {
        this.drawGenderAge();
      }
      if (this.data.topGeo.length > 0) {
        this.drawRegions(); 
      }
      this.drawDevices();
      this.drawSo();
      setTimeout(() => {
        this.globals.loading = false;
      }, 1000);
    }, 500);
  }

  drawDay() {
    let lineColors: any;
    let textColor: any;
    lineColors = [
      am4core.color("#FF5403"),
      am4core.color("#219C90"),
      am4core.color("#40A2E3"),
      am4core.color("#edd92a"),
      am4core.color("#DCFFB7")
    ];
    textColor = am4core.color("#ffffff");
    setTimeout(() => {
      let byday = am4core.create("byDay", am4charts.XYChart);
      let perDay = this.data.perDays;
      byday.legend = new am4charts.Legend();
      byday.legend.labels.template.fill = textColor;
      byday.colors.list = lineColors;
      byday.maskBullets = false;
      byday.data = perDay;
      let byDayDateAxis = byday.xAxes.push(new am4charts.DateAxis());
        byDayDateAxis.renderer.grid.template.location = 0;
        byDayDateAxis.renderer.minGridDistance = 15;
        byDayDateAxis.renderer.labels.template.rotation = -60;
        byDayDateAxis.renderer.labels.template.fill = textColor;
        byDayDateAxis.renderer.labels.template.horizontalCenter = "right";
        byDayDateAxis.renderer.grid.template.disabled = false;
        byDayDateAxis.renderer.grid.template.stroke = textColor;
        byDayDateAxis.renderer.fullWidthTooltip = true;
        byDayDateAxis.skipEmptyPeriods = true;
        let byDayAxis = byday.yAxes.push(new am4charts.ValueAxis());
        byDayAxis.title.text = "Impresiones";
        byDayAxis.renderer.grid.template.disabled = false;
        byDayAxis.title.fill = textColor;
        byDayAxis.renderer.grid.template.stroke = textColor;
        byDayAxis.renderer.labels.template.fill = textColor;
        byDayAxis.renderer.disabled = false;
        let byDayAxisClicks = byday.yAxes.push(new am4charts.ValueAxis());
        byDayAxisClicks.title.text = "Clics";
        byDayAxisClicks.renderer.grid.template.disabled = true;
        byDayAxisClicks.renderer.disabled = false;
        byDayAxisClicks.renderer.opposite = true;
        byDayAxisClicks.title.fill = textColor;
        byDayAxisClicks.renderer.grid.template.stroke = textColor;
        byDayAxisClicks.renderer.labels.template.fill = textColor;
        let byDayAxisCtr = byday.yAxes.push(new am4charts.ValueAxis());
        byDayAxisCtr.title.text = "";
        byDayAxisCtr.renderer.grid.template.disabled = true;
        byDayAxisCtr.renderer.disabled = true;
        let byDayAxisCpc = byday.yAxes.push(new am4charts.ValueAxis());
        byDayAxisCpc.title.text = "";
        byDayAxisCpc.renderer.grid.template.disabled = true;
        byDayAxisCpc.renderer.disabled = true;
        let byDayAxisCpm = byday.yAxes.push(new am4charts.ValueAxis());
        byDayAxisCpm.title.text = "";
        byDayAxisCpm.renderer.grid.template.disabled = true;
        byDayAxisCpm.renderer.disabled = true;
        let byDaySeries;
        byDaySeries = byday.series.push(new am4charts.LineSeries());
        byDaySeries.dataFields.valueY = "imps";
        byDaySeries.dataFields.dateX = "day";
        byDaySeries.yAxis = byDayAxis;
        byDaySeries.name = "Impresiones";
        byDaySeries.strokeWidth = 2;
        byDaySeries.propertyFields.strokeDasharray = "dashLength";
        byDaySeries.tooltipText = "Impresiones: {valueY}";
        byDaySeries.fillOpacity = 0.3;
        byDaySeries.showOnInit = true;
        byDaySeries.tensionX = 0.8;
        let byDaySeriesClicks;
        byDaySeriesClicks = byday.series.push(new am4charts.LineSeries());
        byDaySeriesClicks.dataFields.valueY = "clicks";
        byDaySeriesClicks.dataFields.dateX = "day";
        byDaySeriesClicks.yAxis = byDayAxisClicks;
        byDaySeriesClicks.name = "Clicks";
        byDaySeriesClicks.strokeWidth = 2;
        byDaySeriesClicks.propertyFields.strokeDasharray = "dashLength";
        byDaySeriesClicks.tooltipText = "Clicks: {valueY}";
        byDaySeriesClicks.showOnInit = true;
        byDaySeriesClicks.tensionX = 0.8;
        let byDaySeriesCtr;
        byDaySeriesCtr = byday.series.push(new am4charts.LineSeries());
        byDaySeriesCtr.dataFields.valueY = "ctr";
        byDaySeriesCtr.dataFields.dateX = "day";
        byDaySeriesCtr.yAxis = byDayAxisCtr;
        byDaySeriesCtr.name = "CTR";
        byDaySeriesCtr.strokeWidth = 2;
        byDaySeriesCtr.propertyFields.strokeDasharray = "dashLength";
        byDaySeriesCtr.tooltipText = "Ctr: {valueY}%";
        byDaySeriesCtr.showOnInit = true;
        byDaySeriesCtr.tensionX = 0.8;
        byday.cursor = new am4charts.XYCursor();
        byday.cursor.fullWidthLineX = true;
        byday.cursor.xAxis = byDayDateAxis;
        byday.cursor.lineX.strokeOpacity = 0;
        byday.cursor.lineX.fillOpacity = 0.1;
        // let byDaySeriesCpc;
        // byDaySeriesCpc = byday.series.push(new am4charts.LineSeries());
        // byDaySeriesCpc.dataFields.valueY = "cpc";
        // byDaySeriesCpc.dataFields.dateX = "day";
        // byDaySeriesCpc.yAxis = byDayAxisCpc;
        // byDaySeriesCpc.name = "CPC";
        // byDaySeriesCpc.strokeWidth = 2;
        // byDaySeriesCpc.propertyFields.strokeDasharray = "dashLength";
        // byDaySeriesCpc.tooltipText = "CPC: {valueY}%";
        // byDaySeriesCpc.showOnInit = true;
        // byDaySeriesCpc.tensionX = 0.8;
        // byday.cursor = new am4charts.XYCursor();
        // byday.cursor.fullWidthLineX = true;
        // byday.cursor.xAxis = byDayDateAxis;
        // byday.cursor.lineX.strokeOpacity = 0;
        // byday.cursor.lineX.fillOpacity = 0.1;
        // let byDaySeriesCpm;
        // byDaySeriesCpm = byday.series.push(new am4charts.LineSeries());
        // byDaySeriesCpm.dataFields.valueY = "cpm";
        // byDaySeriesCpm.dataFields.dateX = "day";
        // byDaySeriesCpm.yAxis = byDayAxisCpm;
        // byDaySeriesCpm.name = "CPM";
        // byDaySeriesCpm.strokeWidth = 2;
        // byDaySeriesCpm.propertyFields.strokeDasharray = "dashLength";
        // byDaySeriesCpm.tooltipText = "CPM: {valueY}%";
        // byDaySeriesCpm.showOnInit = true;
        // byDaySeriesCpm.tensionX = 0.8;
        // byday.cursor = new am4charts.XYCursor();
        // byday.cursor.fullWidthLineX = true;
        // byday.cursor.xAxis = byDayDateAxis;
        // byday.cursor.lineX.strokeOpacity = 0;
        // byday.cursor.lineX.fillOpacity = 0.1;
    }, 800);
  }

  drawWeek() {
    let lineColors: any;
    let textColor: any;
    lineColors = [
      am4core.color("#FF5403"),
      am4core.color("#219C90"),
      am4core.color("#40A2E3"),
      am4core.color("#edd92a"),
      am4core.color("#DCFFB7")
    ];
    textColor = am4core.color("#ffffff");
    setTimeout(() => {
      let bydayoftheweek = am4core.create("byWeek", am4charts.XYChart);
      bydayoftheweek.legend = new am4charts.Legend();
      bydayoftheweek.legend.labels.template.fill = textColor;
      bydayoftheweek.colors.list = lineColors;
      bydayoftheweek.maskBullets = false;
      bydayoftheweek.data = this.data.dayOfWeeks;
      let bydayoftheweekdateAxis = bydayoftheweek.xAxes.push(new am4charts.CategoryAxis());
      bydayoftheweekdateAxis.renderer.labels.template.fill = textColor;
      bydayoftheweekdateAxis.dataFields.category = "dayOfWeek";
      bydayoftheweekdateAxis.renderer.grid.template.location = 0;
      bydayoftheweekdateAxis.renderer.minGridDistance = 0;
      bydayoftheweekdateAxis.renderer.grid.template.disabled = false;
      bydayoftheweekdateAxis.renderer.fullWidthTooltip = false;
      bydayoftheweekdateAxis.renderer.grid.template.stroke = textColor;
      let bydayoftheweekAxis = bydayoftheweek.yAxes.push(new am4charts.ValueAxis());
      bydayoftheweekAxis.title.text = "Impresiones";
      bydayoftheweekAxis.renderer.grid.template.disabled = false;
      bydayoftheweekAxis.renderer.disabled = false;
      bydayoftheweekAxis.title.fill = textColor;
      bydayoftheweekAxis.renderer.grid.template.stroke = textColor;
      bydayoftheweekAxis.renderer.labels.template.fill = textColor;
      let bydayoftheweekAxisClicks = bydayoftheweek.yAxes.push(new am4charts.ValueAxis());
      bydayoftheweekAxisClicks.title.text = "Clics";
      bydayoftheweekAxisClicks.renderer.grid.template.disabled = true;
      bydayoftheweekAxisClicks.renderer.disabled = false;
      bydayoftheweekAxisClicks.renderer.opposite = true;
      bydayoftheweekAxisClicks.title.fill = textColor;
      bydayoftheweekAxisClicks.renderer.grid.template.stroke = textColor;
      bydayoftheweekAxisClicks.renderer.labels.template.fill = textColor;
      let bydayoftheweekAxisCtr = bydayoftheweek.yAxes.push(new am4charts.ValueAxis());
      bydayoftheweekAxisCtr.title.text = "";
      bydayoftheweekAxisCtr.renderer.grid.template.disabled = true;
      bydayoftheweekAxisCtr.renderer.disabled = true;
      let bydayoftheweekAxisCpc = bydayoftheweek.yAxes.push(new am4charts.ValueAxis());
      bydayoftheweekAxisCpc.title.text = "";
      bydayoftheweekAxisCpc.renderer.grid.template.disabled = true;
      bydayoftheweekAxisCpc.renderer.disabled = true;
      let bydayoftheweekAxisCpm = bydayoftheweek.yAxes.push(new am4charts.ValueAxis());
      bydayoftheweekAxisCpm.title.text = "";
      bydayoftheweekAxisCpm.renderer.grid.template.disabled = true;
      bydayoftheweekAxisCpm.renderer.disabled = true;
      let bydayoftheweekSeries = bydayoftheweek.series.push(new am4charts.ColumnSeries());
      bydayoftheweekSeries.dataFields.valueY = "imps";
      bydayoftheweekSeries.dataFields.categoryX = "dayOfWeek";
      bydayoftheweekSeries.yAxis = bydayoftheweekAxis;
      bydayoftheweekSeries.propertyFields.strokeDasharray = "dashLength";
      bydayoftheweekSeries.tooltipText = "Impresiones: {valueY}";
      bydayoftheweekSeries.name = "Impresiones";
      bydayoftheweekSeries.showOnInit = true;
      bydayoftheweekSeries.columns.template.column.cornerRadiusTopLeft = 25;
      bydayoftheweekSeries.columns.template.column.cornerRadiusTopRight = 25;
      bydayoftheweekSeries.columns.template.column.cornerRadiusBottomRight = 0;
      bydayoftheweekSeries.columns.template.column.cornerRadiusBottomLeft = 0;

      let bydayoftheweekSeriesClicks = bydayoftheweek.series.push(new am4charts.ColumnSeries());
      bydayoftheweekSeriesClicks.dataFields.valueY = "clicks";
      bydayoftheweekSeriesClicks.dataFields.categoryX = "dayOfWeek";
      bydayoftheweekSeriesClicks.yAxis = bydayoftheweekAxisClicks;
      bydayoftheweekSeriesClicks.propertyFields.strokeDasharray = "dashLength";
      bydayoftheweekSeriesClicks.tooltipText = "Clicks: {valueY}";
      bydayoftheweekSeriesClicks.name = "Clicks";
      bydayoftheweekSeriesClicks.showOnInit = true;
      bydayoftheweekSeriesClicks.columns.template.column.cornerRadiusTopLeft = 25;
      bydayoftheweekSeriesClicks.columns.template.column.cornerRadiusTopRight = 25;
        bydayoftheweekSeriesClicks.columns.template.column.cornerRadiusBottomLeft = 0;
      bydayoftheweekSeriesClicks.columns.template.column.cornerRadiusBottomRight = 0;

      let bydayoftheweekSeriesCtr = bydayoftheweek.series.push(new am4charts.ColumnSeries());
      bydayoftheweekSeriesCtr.dataFields.valueY = "ctr";
      bydayoftheweekSeriesCtr.dataFields.categoryX = "dayOfWeek";
      bydayoftheweekSeriesCtr.yAxis = bydayoftheweekAxisCtr;
      bydayoftheweekSeriesCtr.propertyFields.strokeDasharray = "dashLength";
      bydayoftheweekSeriesCtr.tooltipText = "CTR: {valueY}";
      bydayoftheweekSeriesCtr.name = "CTR";
      bydayoftheweekSeriesCtr.showOnInit = true;
      bydayoftheweekSeriesCtr.columns.template.column.cornerRadiusTopLeft = 25;
      bydayoftheweekSeriesCtr.columns.template.column.cornerRadiusTopRight = 25;
        bydayoftheweekSeriesCtr.columns.template.column.cornerRadiusBottomLeft = 0;
      bydayoftheweekSeriesCtr.columns.template.column.cornerRadiusBottomRight = 0;

      // let bydayoftheweekSeriesCpc = bydayoftheweek.series.push(new am4charts.ColumnSeries());
      // bydayoftheweekSeriesCpc.dataFields.valueY = "cpc";
      // bydayoftheweekSeriesCpc.dataFields.categoryX = "dayOfWeek";
      // bydayoftheweekSeriesCpc.yAxis = bydayoftheweekAxisCpc;
      // bydayoftheweekSeriesCpc.propertyFields.strokeDasharray = "dashLength";
      // bydayoftheweekSeriesCpc.tooltipText = "CPC: {valueY}";
      // bydayoftheweekSeriesCpc.name = "CPC";
      // bydayoftheweekSeriesCpc.showOnInit = true;
      // bydayoftheweekSeriesCpc.columns.template.column.cornerRadiusTopLeft = 25;
      // bydayoftheweekSeriesCpc.columns.template.column.cornerRadiusTopRight = 25;
      //   bydayoftheweekSeriesCpc.columns.template.column.cornerRadiusBottomLeft = 0;
      // bydayoftheweekSeriesCpc.columns.template.column.cornerRadiusBottomRight = 0;

      // let bydayoftheweekSeriesCpm = bydayoftheweek.series.push(new am4charts.ColumnSeries());
      // bydayoftheweekSeriesCpm.dataFields.valueY = "cpm";
      // bydayoftheweekSeriesCpm.dataFields.categoryX = "dayOfWeek";
      // bydayoftheweekSeriesCpm.yAxis = bydayoftheweekAxisCpc;
      // bydayoftheweekSeriesCpm.propertyFields.strokeDasharray = "dashLength";
      // bydayoftheweekSeriesCpm.tooltipText = "CPM: {valueY}";
      // bydayoftheweekSeriesCpm.name = "CPM";
      // bydayoftheweekSeriesCpm.showOnInit = true;
      // bydayoftheweekSeriesCpm.columns.template.column.cornerRadiusTopLeft = 25;
      // bydayoftheweekSeriesCpm.columns.template.column.cornerRadiusTopRight = 25;
      //   bydayoftheweekSeriesCpm.columns.template.column.cornerRadiusBottomLeft = 0;
      // bydayoftheweekSeriesCpm.columns.template.column.cornerRadiusBottomRight = 0;

      bydayoftheweek.cursor = new am4charts.XYCursor();
      bydayoftheweek.cursor.fullWidthLineX = true;
      bydayoftheweek.cursor.xAxis = bydayoftheweekdateAxis;
      bydayoftheweek.cursor.lineX.strokeOpacity = 0;
      bydayoftheweek.cursor.lineX.fill = am4core.color("#000");
      bydayoftheweek.cursor.lineX.fillOpacity = 0.1;
    }, 500);
  }

  drawGenderAge() {
    //gender age
    let byagegender = am4core.create("byAgeGender", am4charts.XYChart);
    byagegender.data = this.data.genderAge;

    //Colors
    let lineColors: any;
    let textColor: any;
    lineColors = [
      am4core.color("#FF5403"),
      am4core.color("#219C90"),
      am4core.color("#40A2E3"),
      am4core.color("#edd92a"),
      am4core.color("#DCFFB7")
    ];
    textColor = am4core.color("#ffffff");

    byagegender.colors.list = lineColors;

    // Use only absolute numbers
    byagegender.numberFormatter.numberFormat = "#.#s";

    // Create axes
    let byagegendercategoryAxis = byagegender.yAxes.push(new am4charts.CategoryAxis());
    byagegendercategoryAxis.renderer.labels.template.fill = textColor;
    byagegendercategoryAxis.dataFields.category = "age";
    byagegendercategoryAxis.renderer.grid.template.location = 0;
    byagegendercategoryAxis.renderer.inversed = true;
    byagegendercategoryAxis.renderer.grid.template.disabled = false;
    byagegendercategoryAxis.renderer.grid.template.stroke = textColor;

    let byagegendervalueAxis = byagegender.xAxes.push(new am4charts.ValueAxis());
    byagegendervalueAxis.renderer.labels.template.fill = textColor;
    byagegendervalueAxis.extraMin = 0.1;
    byagegendervalueAxis.extraMax = 0.1;
    byagegendervalueAxis.renderer.minGridDistance = 40;
    byagegendervalueAxis.renderer.ticks.template.length = 5;
    byagegendervalueAxis.renderer.ticks.template.disabled = false;
    byagegendervalueAxis.renderer.grid.template.disabled = false;
    byagegendervalueAxis.renderer.ticks.template.strokeOpacity = 0.4;
    byagegendervalueAxis.renderer.grid.template.stroke = textColor;
    byagegendervalueAxis.renderer.labels.template.adapter.add("text", function(text) {
      return text == "male" || text == "female" ? text : text + "%";
    })

    // Create series
    let male = byagegender.series.push(new am4charts.ColumnSeries());
    male.dataFields.valueX = "female";
    male.dataFields.categoryY = "age";
    male.clustered = false;
    male.columns.template.column.cornerRadiusBottomRight = 50;
    male.columns.template.column.cornerRadiusTopRight = 50;

    let maleLabel = male.bullets.push(new am4charts.LabelBullet());
    maleLabel.label.text = "F: {valueX}%";
    maleLabel.label.fill = textColor;
    maleLabel.label.hideOversized = true;
    maleLabel.label.truncate = false;
    maleLabel.label.horizontalCenter = "left";
    maleLabel.label.dx = 10;

    let female = byagegender.series.push(new am4charts.ColumnSeries());
    female.dataFields.valueX = "male";
    female.dataFields.categoryY = "age";
    female.clustered = false;
    female.columns.template.column.cornerRadiusBottomLeft = 50;
    female.columns.template.column.cornerRadiusTopLeft = 50;

    let femaleLabel = female.bullets.push(new am4charts.LabelBullet());
    femaleLabel.label.text = "M: {valueX}%";
    femaleLabel.label.fill = textColor;
    femaleLabel.label.hideOversized = false;
    femaleLabel.label.truncate = false;
    femaleLabel.label.horizontalCenter = "right";
    femaleLabel.label.dx = -10;

    let maleRange = byagegendervalueAxis.axisRanges.create();
    maleRange.value = 10;
    maleRange.endValue = 0;
    maleRange.label.text = "Mujeres";
    maleRange.label.fill = lineColors[0];
    maleRange.label.dy = 20;
    maleRange.label.fontWeight = '600';
    maleRange.grid.strokeOpacity = 1;
    maleRange.grid.stroke = male.stroke;

    let femaleRange = byagegendervalueAxis.axisRanges.create();
    femaleRange.value = 0;
    femaleRange.endValue = -10;
    femaleRange.label.text = "Hombres";
    femaleRange.label.fill = lineColors[1];
    femaleRange.label.dy = 20;
    femaleRange.label.fontWeight = '600';
    femaleRange.grid.strokeOpacity = 1;
    femaleRange.grid.stroke = female.stroke;
  }

  viewCreative(creative: any) {
    this.selectedCreative = creative.file;
    this.creativeType = creative.type;
    if (creative.type === 'tag') {
      let prev = (document.getElementById(`prevCreative`) as HTMLElement);
      prev.innerHTML = '';
      let frame = document.createElement('iframe');
      frame.width = '100%';
      frame.height = '300';
      frame.src = 'data:text/html;charset=utf-8,' + encodeURI(this.selectedCreative);
      prev.appendChild(frame);
    }
  }

  drawRegions() {
    //Colors
    let lineColors: any;
    let textColor: any;
    lineColors = [
      am4core.color("#FF5403"),
      am4core.color("#219C90"),
      am4core.color("#40A2E3"),
      am4core.color("#edd92a"),
      am4core.color("#DCFFB7")
    ];
    textColor = am4core.color("#ffffff");
    const data = this.data.maps;
    let defaultMap = this.data.maps.countries[0].file;
    let mapSelected = this.data.maps.countries[0].file;
    let currentMap = defaultMap;
    let title = this.data.maps.countries[0].code;
    let chart = am4core.create("byregion", am4maps.MapChart);
    // chart.titles.create().text = title;
    chart.colors.list = lineColors;
    chart.geodataSource.url = "https://www.amcharts.com/lib/4/geodata/json/" + currentMap + 'Low.json';
    chart.projection = new am4maps.projections.Mercator();
    let polygonSeries = chart.series.push(new am4maps.MapPolygonSeries());
    polygonSeries.data = _.filter(data.regions, {code: this.data.maps.countries[0].code});
    polygonSeries.heatRules.push({
      property: "fill",
      target: polygonSeries.mapPolygons.template,
      min: chart.colors.getIndex(1).brighten(1),
      max: chart.colors.getIndex(1).brighten(-0.3)
    });
    polygonSeries.useGeodata = true;
    let heatLegend = chart.createChild(am4maps.HeatLegend);
    heatLegend.series = polygonSeries;
    heatLegend.align = "right";
    heatLegend.width = am4core.percent(25);
    heatLegend.marginRight = am4core.percent(4);
    heatLegend.minValue = 0;
    heatLegend.maxValue = this.data.general.imps;
    heatLegend.valign = "bottom";
    let minRange = heatLegend.valueAxis.axisRanges.create();
    minRange.value = heatLegend.minValue;
    minRange.label.text = "Menos";
    let maxRange = heatLegend.valueAxis.axisRanges.create();
    maxRange.value = heatLegend.maxValue;
    maxRange.label.text = "Más!";
    heatLegend.valueAxis.renderer.labels.template.adapter.add("text", function(labelText) {
      return "";
    });
    let polygonTemplate = polygonSeries.mapPolygons.template;
    polygonTemplate.tooltipText = "{name}: {value}";
    polygonTemplate.nonScalingStroke = true;
    polygonTemplate.strokeWidth = 0.5;
    let hs = polygonTemplate.states.create("hover");
    hs.properties.fill = chart.colors.getIndex(0).brighten(-0.5);
  }

  drawDevices() {
    //Colors
    let lineColors: any;
    let textColor: any;
    let borderColor: any;
    lineColors = [
      am4core.color("#FF5403"),
      am4core.color("#219C90"),
      am4core.color("#40A2E3"),
      am4core.color("#edd92a"),
      am4core.color("#DCFFB7")
    ];
    textColor = am4core.color("#ffffff");
    borderColor = am4core.color("#fff");
    am4core.useTheme(am4themes_animated);
    let devices = am4core.create("byDevices", am4charts.PieChart);
    devices.data = this.data.devices;
    let pieSeries = devices.series.push(new am4charts.PieSeries());
    pieSeries.colors.list = lineColors;
    pieSeries.labels.template.fill = textColor;
    pieSeries.dataFields.value = "imps";
    pieSeries.dataFields.category = "name";
    pieSeries.slices.template.stroke = borderColor;
    pieSeries.slices.template.strokeWidth = 1;
    pieSeries.slices.template.strokeOpacity = 1;
    pieSeries.hiddenState.properties.opacity = 1;
    pieSeries.hiddenState.properties.endAngle = -90;
    pieSeries.hiddenState.properties.startAngle = -90;
  }

  drawSo() {
    //Colors
    let lineColors: any;
    let textColor: any;
    let borderColor: any;
    lineColors = [
      am4core.color("#FF5403"),
      am4core.color("#219C90"),
      am4core.color("#40A2E3"),
      am4core.color("#edd92a"),
      am4core.color("#DCFFB7")
    ];
    textColor = am4core.color("#ffffff");
    borderColor = am4core.color("#fff");
    let chart = am4core.create("bySo", am4charts.PieChart);
    chart.data = this.data.os;
    chart.radius = am4core.percent(70);
    chart.innerRadius = am4core.percent(40);
    chart.startAngle = 180;
    chart.endAngle = 360;
    let series = chart.series.push(new am4charts.PieSeries());
    series.colors.list = lineColors;
    series.labels.template.fill = textColor;
    series.dataFields.value = "imps";
    series.dataFields.category = "name";
    series.slices.template.cornerRadius = 10;
    series.slices.template.innerCornerRadius = 7;
    series.slices.template.draggable = true;
    series.slices.template.inert = true;
    series.alignLabels = true;
    series.hiddenState.properties.startAngle = 90;
    series.hiddenState.properties.endAngle = 90;
  }

  downLoadXlsx() {
    this.globals.loading = true;
    this.service.saveXlsx({title: `${this.globals.selectedReport.name} - desde el ${this.globals.selectedReport.st} al ${this.globals.selectedReport.ed}`, data: this.data});
    this.globals.loading = false;
  }

}
