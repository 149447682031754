import { Injectable } from '@angular/core';
import { HttpHeaders, HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor() { }

  getToken() {
    let token = 'n/a';
    if (localStorage.getItem('token')) {
      token = localStorage.getItem('token') || 'n/a';
    }
    const headers = new HttpHeaders({
      Authorization: `Bearer ${token}`,
      'Access-Control-Allow-Origin': '*'
    });

    return headers;
  }

}
