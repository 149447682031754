<div class="body">
    <div class="header">
        <div class="search">
            <input class="input" placeholder="Buscar" id="search">
        </div>
        <div class="btnAdd text-truncate" data-bs-toggle="modal" data-bs-target="#modal">+ Agregar</div>
    </div>

    <div class="title">Usuarios <span class="badge">{{ users.length }}</span></div>

    <div class="lister">
        <table class="table">
            <thead>
              <tr>
                <th scope="col">Nombre</th>
                <th scope="col">Email</th>
                <th scope="col">Rol</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let u of users" [class.disabled]="u.email === 'data@containermedia.net'">
                <td class="w-25">
                    <div class="image" attr.style="background-image: url({{ u.picture }});"></div>
                    <div class="name">{{ u.name }}</div>
                </td>
                <td class="w-50"><div class="name">{{ u.email }}</div></td>
                <td class="w-20"><div class="name">{{ u.role }}</div></td>
                <td>
                    <!-- <i class="lni lni-pencil"></i> -->
                    <i class="lni lni-remove-file" *ngIf="u.email !== 'data@containermedia.net'" (click)="deleteUser(u._id)"></i>
                </td>
              </tr>
            </tbody>
          </table>
    </div>

</div>


<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">
        <div class="modal-body">
            <div class="title">Añadir Usuario</div>
            <div class="text-center">
                <div class="inputImage" attr.style="background-image: url({{ selectedImage }});">
                    <label class="upload" *ngIf="selectedImage === ''" for="image">
                        <div class="icon"><i class="lni lni-cloud-upload"></i></div>
                        <div class="text">Sube la imagen que quieras</div>
                    </label>
                    <div class="delete" *ngIf="selectedImage !== ''" (click)="deleteImage()">
                        <div class="icon"><i class="lni lni-remove-file"></i></div>
                    </div>
                </div>
            </div>
            <input id="image" type="file" style="display: none;" (change)="uploadImage()">
            <input class="input" placeholder="Nombre de Usuario" id="name">
            <input class="input" placeholder="Correo Electronico" id="email">
            <input class="input" placeholder="Contraseña" id="password">
            <select class="input" id="role">
                <option selected disabled>Seleecione un Rol</option>
                <option value="superadmin">Super Administrador</option>
                <option value="admin">Administrador</option>
                <option value="editor">Editor</option>
                <option value="analist">Analista</option>
            </select>
            <div class="btnAdd" (click)="createUser()" data-bs-dismiss="modal">Crear</div>
        </div>
      </div>
    </div>
  </div>