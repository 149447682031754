import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Daterangepicker } from 'ng2-daterangepicker';

import { ReportRoutingModule } from './report-routing.module';
import { ReportComponent } from './report.component';
import { Warnertemplate01Component } from '../importables/templates/warnertemplate01/warnertemplate01.component';
import { Warnertemplate02Component } from '../importables/templates/warnertemplate02/warnertemplate02.component';
import { Netflixtemplate01Component } from '../importables/templates/netflixtemplate01/netflixtemplate01.component';
import { InternalreportComponent } from './../importables/internalreport/internalreport.component';


@NgModule({
  declarations: [
    ReportComponent,
    Warnertemplate01Component,
    Warnertemplate02Component,
    Netflixtemplate01Component,
    InternalreportComponent
  ],
  imports: [
    CommonModule,
    ReportRoutingModule,
    Daterangepicker
  ]
})
export class ReportModule { }
