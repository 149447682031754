<div class="body" *ngIf="companies">
    <div class="header">
        <div class="search">
            <input class="input" placeholder="Buscar" id="search">
        </div>
        <div class="btnAdd text-truncate" data-bs-toggle="modal" data-bs-target="#modal" (click)="create()">+ Agregar</div>
    </div>

    <div class="title">Compañias <span class="badge">{{ companies.length }}</span></div>

    <div class="lister">
        <table class="table">
            <thead>
              <tr>
                <th scope="col">ID</th>
                <th scope="col">Nombre</th>
                <th scope="col">Usuarios</th>
                <th scope="col">Acciones</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let c of companies">
                <td>
                  <div class="name">
                     {{c._id }}
                  </div>
                </td>
                <td>
                  <div class="name">
                     {{c.name }}
                  </div>
                </td>
                <td class="w-75">
                  <div class="users">
                    <div class="user" *ngFor="let u of c.filterUsers" attr.style="background-image: url({{ u.picture }});">
                      <div class="name">{{ u.name }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <i class="lni lni-pencil" (click)="edit(c)" data-bs-toggle="modal" data-bs-target="#modal"></i>
                </td>
              </tr>
            </tbody>
          </table>
    </div>

</div>


<div class="modal fade" id="modal" tabindex="-1" aria-labelledby="modalLabel" aria-hidden="true">
    <div class="modal-dialog">
      <div class="modal-content">

        <div class="modal-body" *ngIf="globals.editCompany === ''">
            <div class="title">Añadir Compañia</div>
            <input class="input" placeholder="Nombre de la compañia" id="name">
            <div class="title">Añadir Usuarios</div>
            <div class="row">
              <div class="col" *ngFor="let u of users">
                <div class="user" attr.style="background-image: url({{ u.picture }});" [class.selected]="u.selected" (click)="selectUser(u._id)">
                  <div class="name">{{ u.name }}</div>
                </div>
              </div>
            </div>
            <div class="btnAdd" data-bs-dismiss="modal" (click)="createCompany()">Crear</div>
        </div>

        
        <div class="modal-body" *ngIf="globals.editCompany !== ''">
          <div class="title">Editar Compañia</div>
          <input class="input" placeholder="Nombre de la compañia" id="name">
          <div class="title">Añadir o eliminar Usuarios</div>
          <div class="row">
            <div class="col" *ngFor="let u of users">
              <div class="user" attr.style="background-image: url({{ u.picture }});" [class.selected]="u.selected" (click)="selectUser(u._id)">
                <div class="name">{{ u.name }}</div>
              </div>
            </div>
          </div>
          <div class="btnAdd" data-bs-dismiss="modal" (click)="saveEdit()">Editar</div>
      </div>

      </div>
    </div>
  </div>