<div class="bodyCreate">
    <div class="selectors">
        <app-one *ngIf="state === 0"></app-one>
        <app-two *ngIf="state === 1"></app-two>
        <app-three *ngIf="state === 2"></app-three>
    </div>
    <div class="steps">
        <div class="resume" *ngIf="globals.campaign">
            <div class="CampaignName"><span class="code">{{ globals.campaign.code }}{{ globals.campaign.counter }}</span> {{ globals.campaign.name }}</div>

            <div *ngIf="state === 0">
                <div class="item"><span class="title">Facturación:</span> {{ globals.campaign.billing }}</div>
                <div class="item"><span class="title">Compañia:</span> {{ globals.campaign.company }}</div>
                <div class="item"><span class="title">Marca:</span> {{ globals.campaign.brand }}</div>
                <div class="item"><span class="title">Responsable:</span> {{ globals.campaign.response }}</div>
                <div class="item"><span class="title">País:</span> {{ globals.campaign.country }}</div>
                <div class="item"><span class="tilte">Formatos:</span> </div>
                <div class="format" *ngFor="let f of globals.campaign.formats">
                    <div class="df">
                        <div class="icon" attr.style="background-image: url({{f.icon}});" *ngIf="f.name !== 'Youtube'"></div>
                        <div class="icon" attr.style="background-image: url({{f.icon}}); filter: invert(1);" *ngIf="f.name === 'Youtube'"></div>
                        <div class="itemdf"><span class="title">Fee </span><br>{{ f.feePercent }}%</div>
                        <div class="itemdf"><span class="title">Presupuesto </span><br>${{ f.budget | number }} {{ globals.campaign.currency }}</div>    
                    </div>
                    <div class="order">{{ f.orderId }}</div>
                </div>
    
                <div class="btnSave" [class.disabled]="!globals.campaign.readySaveOne" (click)="saveOne()">Guardar Configuración</div>
            </div>

            <div *ngIf="state === 1">
                <div class="format" *ngFor="let f of globals.campaign.formats">
                    <div class="df">
                        <div class="icon" attr.style="background-image: url({{f.icon}});" *ngIf="f.name !== 'Youtube'"></div>
                        <div class="icon" attr.style="background-image: url({{f.icon}}); filter: invert(1);" *ngIf="f.name === 'Youtube'"></div>
                        <div class="itemdf" *ngIf="f.days"><span class="title">Días </span><br>{{ f.days.length }}</div>
                        <div class="itemdf" *ngIf="f.creatives"><span class="title">Creativos </span><br>{{ f.creatives.length }}</div>    
                    </div>
                </div>

                <div class="row">
                    <div class="col">
                        <div class="btnSave"  (click)="saveTwo()">Guardar Datos</div>
                    </div>
                    <div class="col">
                        <div class="btnCustom" *ngIf="globals.campaign.state === 2" (click)="customer()">Personalizar <i class="lni lni-paint-roller"></i></div>
                    </div>
                </div>
            </div>

            <div *ngIf="state === 2">

                <div *ngIf="this.globals.campaign.selectedTemplate._id === '65bc16f285f6ec9dbb025e9d'">

                    <div class="item"><span class="title">Titulo súperior:</span> {{this.globals.campaign.selectedTemplate.topBar}}</div>

                    <div class="item"><span class="title">Iconos y Logos:</span></div>
                    <div class="colors">
                        <div class="image" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.brand.letter}});"></div>
                        <div class="image" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.brand.icon}});"></div>
                    </div>
    
                    <div class="item"><span class="title">Colores:</span></div>
                    <div class="colors">
                        <div class="color" attr.style="background-color: #{{c}};" *ngFor="let c of this.globals.campaign.selectedTemplate.colors"></div>
                    </div>
    
                    <div class="item"><span class="title">Foto del artista:</span></div>
                    <div class="image" *ngIf="this.globals.campaign.selectedTemplate.artistImage || this.globals.campaign.selectedTemplate.artistImage !== ''">
                        <div class="artist" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.artistImage}});">
                            <div class="delete" (click)="deleteImage()">
                                <i class="lni lni-remove-file"></i>
                            </div>
                        </div>
                    </div>

                    <div class="item"><span class="title">Nombre de la canción:</span> {{this.globals.campaign.selectedTemplate.namesong}}</div>

    
                    <div class="item"><span class="title">Canción del artista:</span></div>
                    <div class="audio" *ngIf="this.globals.campaign.selectedTemplate.song || this.globals.campaign.selectedTemplate.song !== ''">
                        <audio [src]="this.globals.campaign.selectedTemplate.song" controls></audio>
                        <i class="lni lni-cross-circle" (click)="deleteAudio()" *ngIf="this.globals.campaign.selectedTemplate.song"></i>
                    </div>
                </div>

                <div *ngIf="this.globals.campaign.selectedTemplate._id === '65c2607ed7189f3d7e00110c'">

                    <div class="item"><span class="title">Titulo súperior:</span> {{this.globals.campaign.selectedTemplate.topBar}}</div>

                    <div class="item"><span class="title">Iconos y Logos:</span></div>
                    <div class="colors">
                        <div class="image" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.brand.letter}});"></div>
                        <div class="image" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.brand.icon}});"></div>
                    </div>
    
                    <div class="item"><span class="title">Colores:</span></div>
                    <div class="colors">
                        <div class="color" attr.style="background-color: #{{c}};" *ngFor="let c of this.globals.campaign.selectedTemplate.colors"></div>
                    </div>
    
                    <div class="item"><span class="title">Foto del artista:</span></div>
                    <div class="image" *ngIf="this.globals.campaign.selectedTemplate.artistImage || this.globals.campaign.selectedTemplate.artistImage !== ''">
                        <div class="artist" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.artistImage}});">
                            <div class="delete" (click)="deleteImage()">
                                <i class="lni lni-remove-file"></i>
                            </div>
                        </div>
                    </div>

                    <div class="item"><span class="title">Nombre de la canción:</span> {{this.globals.campaign.selectedTemplate.namesong}}</div>

    
                    <div class="item"><span class="title">Canción del artista:</span></div>
                    <div class="audio" *ngIf="this.globals.campaign.selectedTemplate.song || this.globals.campaign.selectedTemplate.song !== ''">
                        <audio [src]="this.globals.campaign.selectedTemplate.song" controls></audio>
                        <i class="lni lni-cross-circle" (click)="deleteAudio()" *ngIf="this.globals.campaign.selectedTemplate.song"></i>
                    </div>
                </div>


                <div *ngIf="this.globals.campaign.selectedTemplate._id === '65c54a01446648e57ca38c21'">

                    <div class="item"><span class="title">Titulo súperior:</span> {{this.globals.campaign.selectedTemplate.topBar}}</div>

                    <div class="item"><span class="title">Iconos y Logos:</span></div>
                    <div class="colors">
                        <div class="image" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.brand.letter}});"></div>
                        <div class="image" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.brand.icon}});"></div>
                    </div>
    
                    <div class="item"><span class="title">Colores:</span></div>
                    <div class="colors">
                        <div class="color" attr.style="background-color: #{{c}};" *ngFor="let c of this.globals.campaign.selectedTemplate.colors"></div>
                    </div>
    
                    <div class="item"><span class="title">Foto de Fondo:</span></div>
                    <div class="image" *ngIf="this.globals.campaign.selectedTemplate.artistImage || this.globals.campaign.selectedTemplate.artistImage !== ''">
                        <div class="artist" attr.style="background-image: url({{this.globals.campaign.selectedTemplate.artistImage}});">
                            <div class="delete" (click)="deleteImage()">
                                <i class="lni lni-remove-file"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="btnCustom" *ngIf="globals.campaign.state === 2" (click)="saveCustomer()">Guardar Holograma <i class="lni lni-save"></i></div>

            </div>

        </div>
    </div>
</div>