import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { AdminService } from '../services/admin.service';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {

  globals: any;
  template: any;

  constructor(private service: AdminService, globals: Globals, private active: ActivatedRoute, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.globals.idReport = this.active.snapshot.paramMap.get('id');
    this.globals.reportVersion = this.active.snapshot.paramMap.get('variant');
    this.getCampaign();
  }

  getCampaign() {
    this.globals.loading = true;
    this.service.getReport(this.globals.idReport).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        for (let f = 0; f < data.formats.length; f++) {
          if (data.formats[f].id === '65de7e98bfc912a6a65c28bd') {
            data.formats.splice(f, 1)
          }          
        }
        this.globals.campaign = data;
        this.template = this.globals.campaign.template._id;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  showReport() {
    this.globals.report = true;
  }

  close() {
    this.globals.report = false;
  }

}
