import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root'
})
export class NavbarService {

  constructor(private http: HttpClient, private auth: AuthService) { }

  getUserInfo(id: any) {
    const headers = this.auth.getToken();
    return this.http.get(`${environment.urlApi}/users/user?id=${id}`, { headers });
  }

  putPassword(body: any) {
    const headers = this.auth.getToken();
    return this.http.put(`${environment.urlApi}/users/password`, body, { headers });
  }
}
