<div class="naver">
    <!-- <select class="navInput" id="dashCompanies">
        <option>CM Group</option>
    </select> -->
    <div class="data">
        <div class="name">{{ globals.user.name }}</div>
        <div class="email tex-truncate">{{ globals.user.email }}</div>
    </div>
    <div class="profilePicture" attr.style="background-image: url({{ globals.user.picture }});" routerLink="/home/profile"></div>
    <div class="line"></div>
    <div class="close" (click)="close()"><i class="lni lni-exit"></i></div>
</div>