import { Globals } from 'src/app/globals';
import { Component, OnInit } from '@angular/core';
import { AdminService } from '../../services/admin.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import * as _ from 'lodash';
import moment from 'moment-timezone';

@Component({
  selector: 'app-three',
  templateUrl: './three.component.html',
  styleUrls: ['./three.component.scss']
})
export class ThreeComponent implements OnInit {

  globals: any;
  templates: any;
  c: any;
  brands: any;

  constructor(globals: Globals, private service: AdminService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.getTemplates();
    this.c = this.globals.campaign;
    this.getBrandsByCompany();
  }

  getBrandsByCompany() {
    this.globals.loading = true;
    this.service.getBrandsByCompany(this.c.company).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.brands = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  getTemplates() {
    this.globals.loading = true;
    this.service.getTemplates().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.templates = data;
        setTimeout(() => {
          if (this.c.template) {
            (document.getElementById('template') as HTMLInputElement).value = this.c.template._id;
            (document.getElementById('template') as HTMLInputElement).disabled = true;
            this.selectTemplate();
          }
        }, 1000);
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
}

selectTemplate() {
  if (!this.c.template) {
    let t = (document.getElementById('template') as HTMLInputElement).value;
    let p = _.findIndex(this.templates, {_id: t});
    this.c.selectedTemplate = this.templates[p];
    let pc = _.findIndex(this.brands, {_id: this.c.brand});
    this.c.selectedTemplate.colors = this.brands[pc].colors;
    this.c.selectedTemplate.brand = this.brands[pc];
  } else {
    this.c.selectedTemplate = this.c.template;
    setTimeout(() => {
      (document.getElementById('namesong') as HTMLInputElement).value = this.c.template.namesong;
      (document.getElementById('title') as HTMLInputElement).value = this.c.template.topBar;
    }, 1000);
  }
}

uploadCreative(id: any) {
  let file = (document.getElementById(`file${id}`) as HTMLInputElement).files[0];
  this.globals.loading = true;
  var fd = new FormData();
  fd.append('fileName', file);
  fd.append('route', `dataroom/templates/${id}`);
  this.service.upload(fd).subscribe((data: any) => {
    if (data.success === 0) {
      Swal.fire('', data.message.message, 'error');
      this.globals.loading = false;
    } else if(data.success === 2) {
      localStorage.removeItem('token');
      localStorage.removeItem('druuid');
      this.router.navigate(['']);
    } else {
      this.globals.loading = false;
      if (id === 'Artist') {
        this.c.selectedTemplate.artistImage = data.message.root;
      }
      if (id === 'Song') {
        this.c.selectedTemplate.song = data.message.root;
      }
      if (id === 'Background') {
        this.c.selectedTemplate.backgroundImage = data.message.root;
      }
    }
  }, err => {
    this.globals.loading = false;
    return Swal.fire('', err.message, 'error');
  });
}

setTitle() {
  let name = (document.getElementById('title') as HTMLInputElement).value;
  this.c.selectedTemplate.topBar = name;
}

setNameSong() {
  let name = (document.getElementById('namesong') as HTMLInputElement).value;
  this.c.selectedTemplate.namesong = name;
}

}
