import { Globals } from 'src/app/globals';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';
import { AdminService } from '../../services/admin.service';

@Component({
  selector: 'app-companies',
  templateUrl: './companies.component.html',
  styleUrls: ['./companies.component.scss']
})
export class CompaniesComponent implements OnInit {

  globals: any;
  companies: any;
  users: any;

  constructor(globals: Globals, private service: AdminService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.getUserInfo();
  }

  getUserInfo() {
    this.globals.loading = true;
    this.service.getUsers().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.users = data;
        this.getCompanies();
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  getCompanies() {
    this.globals.loading = true;
    this.service.getCompanies().subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.companies = data;
        for (const c of this.companies) {
          c.filterUsers = [];
          for (const uc of c.users) {
            for (const u of this.users) {
              u.selected = false;
              if (uc === u._id) {
                c.filterUsers.push(u);
              }
            }
          }
        }
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  selectUser(id: any) {
    for (const u of this.users) {
      if (u._id === id) {
        if (u.selected) {
          u.selected = false;
        } else {
          u.selected = true;
        }
      }
    }
  }

  createCompany() {
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const users = [];
    for (const u of this.users) {
      if (u.selected) {
        users.push(u._id);
      }
    }
    if (users.length === 0) {
      Swal.fire('', 'Debe seleccionar al menos un usuario', 'error');
    } else {
      this.globals.loading = true;
      this.service.createCompanie({name, users}).subscribe((data: any) => {
        if (data.success === 0) {
          Swal.fire('', data.message.message, 'error');
          this.globals.loading = false;
        } else if(data.success === 2) {
          localStorage.removeItem('token');
          localStorage.removeItem('druuid');
          this.router.navigate(['']);
        } else {
          this.globals.loading = false;
          this.getCompanies();
        }
      }, err => {
        this.globals.loading = false;
        return Swal.fire('', err.message, 'error');
      });
    }
  }

  create() {
    this.globals.editCompany = '';
    for (const u of this.users) {
      u.selected = false;
    }
  }

  edit(company: any) {
    this.globals.editCompany = company._id;
    (document.getElementById('name') as HTMLInputElement).value = company.name;
    for (const u of this.users) {
      for (const uc of company.users) {
        if (u._id === uc) {
          u.selected = true;
        } else {
          u.selected = false;
        }
      }
    }
  }

  saveEdit() {
    const name = (document.getElementById('name') as HTMLInputElement).value;
    const users = [];
    for (const u of this.users) {
      if (u.selected) {
        users.push(u._id);
      }
    }
    if (users.length === 0) {
      Swal.fire('', 'Debe seleccionar al menos un usuario', 'error');
    } else {
      this.globals.loading = true;
      this.service.editCompany({id: this.globals.editCompany, name, users}).subscribe((data: any) => {
        if (data.success === 0) {
          Swal.fire('', data.message.message, 'error');
          this.globals.loading = false;
        } else if(data.success === 2) {
          localStorage.removeItem('token');
          localStorage.removeItem('druuid');
          this.router.navigate(['']);
        } else {
          this.globals.loading = false;
          this.getCompanies();
        }
      }, err => {
        this.globals.loading = false;
        return Swal.fire('', err.message, 'error');
      });
    }
  }

}
