import { Globals } from 'src/app/globals';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss']
})
export class LoaderComponent implements OnInit {

  globals: any;

  constructor(globals: Globals, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    setTimeout(() => {
      this.router.navigate(['login']);
    }, 4000);
  }

}
