import { Component, OnInit } from '@angular/core';
import { Globals } from 'src/app/globals';
import { NavbarService } from '../services/navbar.service';
import { Router } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  globals: any;
  userId: any;

  constructor(globals: Globals, private service: NavbarService, private router: Router) {
    this.globals = globals;
  }

  ngOnInit(): void {
    this.userId = localStorage.getItem('druuid');
    this.getUserInfo();
  }

  getUserInfo() {
    this.globals.loading = true;
    this.service.getUserInfo(this.userId).subscribe((data: any) => {
      if (data.success === 0) {
        Swal.fire('', data.message.message, 'error');
        this.globals.loading = false;
      } else if(data.success === 2) {
        localStorage.removeItem('token');
        localStorage.removeItem('druuid');
        this.router.navigate(['']);
      } else {
        this.globals.loading = false;
        this.globals.user = data;
      }
    }, err => {
      this.globals.loading = false;
      return Swal.fire('', err.message, 'error');
    });
  }

  close() {
    localStorage.removeItem('token');
    localStorage.removeItem('druuid');
    this.router.navigate(['']);
  }

}
